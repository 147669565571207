//医生端-登录
export const DOCTOR_LOGIN_ACTION = 'DOCTOR_LOGIN_ACTION'
export const DOCTOR_LOGIN_ACTION_SUCCEED = 'DOCTOR_LOGIN_ACTION_SUCCEED'
export const LOGIN_FAILED="LOGIN_FAILED"

export const LOGOUT = 'LOGOUT'
export const SERVER_CONNECT_FAILED='SERVER_CONNECT_FAILED' //连接失败

//患者推介 begin
export const GET_MYINTRODUCELIST='GET_MYINTRODUCELIST' //我的推介
export const MYINTRODUCELIST_ARRIVED='MYINTRODUCELIST_ARRIVED' //我的推介列表数据获取成功

export const POST_INTRODUCENEWINFO='POST_INTRODUCENEWINFO' //患者推介-新建推介
export const SUBMITINTRODUCENEWINFO_ARRIVED='SUBMITINTRODUCENEWINFO_ARRIVED' //患者推介-新建推介-请求成功
export const RESET_INTRODUCE_NEWREPLY = 'RESET_INTRODUCE_NEWREPLY' //患者推介-重置新建推介返回码

export const INTRODUCE_DETAIL = 'INTRODUCE_DETAIL'//我的推介-查看详情
export const INTRODUCE_DETAIL_SUCCEED = "INTRODUCE_DETAIL_SUCCEED";//我的推介-查看详情 获取数据成功

export const GET_NOTICELIST='GET_NOTICELIST' //我的推介-通知列表
export const NOTICELIST_ARRIVED='NOTICELIST_ARRIVED' //我的推介-通知列表 获取数据成功

export const NOTICE_READED = 'NOTICE_READED'
export const NOTICE_READED_SUCCEED = 'NOTICE_READED_SUCCEED'

export const INTRODUCE_NOTICE_DAYS7 = 'INTRODUCE_NOTICE_DAYS7'
export const INTRODUCE_NOTICE_DAYS7_SUCCEED = 'INTRODUCE_NOTICE_DAYS7_SUCCEED'

export const INTRODUCE_NOTIFY_TREATED = 'INTRODUCE_NOTIFY_TREATED'
export const INTRODUCE_NOTIFY_TREATED_SUCCEED = 'INTRODUCE_NOTIFY_TREATED_SUCCEED'


export const INTRODUCE_NOTICE_DETAIL ='INTRODUCE_NOTICE_DETAIL'
export const INTRODUCE_NOTICE_DETAIL_SUCCEED ='INTRODUCE_NOTICE_DETAIL_SUCCEED'

export const GET_REASONLIST = 'GET_REASONLIST';//患者推介-新建推介-推介原因
export const SET_REASONLIST = 'SET_REASONLIST';//患者推介-新建推介-推介原因

//患者推介 end


//患者追踪begin
export const GET_MYTRACKLIST = 'GET_MYTRACKLIST' //患者追踪 - 我的追踪
export const MYTRACKLIST_ARRIVED = 'MYTRACKLIST_ARRIVED' //获取到了我的追踪列表

export const GET_MYTRACKNOTICELIST='GET_MYTRACKNOTICELIST' //患者追踪 - 通知 列表
export const MYTRACKNOTICELIST_ARRIVED='MYTRACKNOTICELIST_ARRIVED' //获取到了追踪通知列表


export const TRACK_APPO_INTMENT = 'TRACK_APPO_INTMENT' //患者追踪-通知- 详情页
export const TRACK_APPO_INTMENT_SUCCEED = 'TRACK_APPO_INTMENT_SUCCEED'

export const TRACK_FAILURE = 'TRACK_FAILURE' //患者追踪- 详情页-追踪失败
export const TRACK_FAILURE_SUCCEED = 'TRACK_FAILURE_SUCCEED'





//患者追踪end

//患者管理
export const GET_MANAGELIST='GET_MANAGELIST'// 患者管理我的列表
export const MANAGELIST_ARRIVED='MANAGELIST_ARRIVED'//获取到了患者管理我的列表


export const POST_APPOINTMENTINFO='POST_APPOINTMENTINFO' //向服务器提交预约的信息
export const SUBMITAPPOINTMENTINFO_ARRIVED='SUBMITAPPOINTMENTINFO_ARRIVED' //向服务器提交预约信息的信息的返回

export const POST_APPOINTMENTFAILEDINFO = 'POST_APPOINTMENTFAILEDINFO' //向服务器提交预约失败的信息
export const SUBMITAPPOINTMENTFAILEDINFO_ARRIVED = 'SUBMITAPPOINTMENTFAILEDINFO_ARRIVED' //向服务器提交预约失败信息的信息的返回
export const POST_APPOINTMENTRESERVEINFO = 'POST_APPOINTMENTRESERVEINFO' //向服务器提交预约的信息
export const SUBMITAPPOINTMENTRESERVEINFO_ARRIVED = 'SUBMITAPPOINTMENTRESERVEINFO_ARRIVED' //向服务器提交预约信息的信息的返回
export const POST_SENDINTRODUCEINFO = 'POST_SENDINTRODUCEINFO' //向服务器提交发送推介的信息
export const SUBMITSENDINTRODUCEINFO_ARRIVED = 'SUBMITSENDINTRODUCEINFO_ARRIVED' //向服务器提交发送推介的信息的返回



//个人信息
export const GET_MYINFO='GET_MYINFO' //向服务器请求我的信息
export const MYINFO_ARRIVED="MYINFO_ARRIVED"//成功获取到服务器返回的我的信息

export const GET_REGIONLIST='GET_REGIONLIST'//从服务器获取住址信息
export const SET_REGIONLIST='SET_REGIONLIST'//将住址信息放入redux

export const GET_CLASSLIST='GET_CLASSLIST'//从服务器获取班级信息
export const SET_CLASSLIST='SET_CLASSLIST'//将班级信息放入redux

//医生端我的
export const GET_MYVIEW = 'GET_MYVIEW' //医生端我的资料
export const GET_MYVIEW_SUCCEED = 'GET_MYVIEW_SUCCEED'

export const POST_PHONENUMBER = 'POST_PHONENUMBER' //医生端我的手机号码修改提交
export const POST_PHONENUMBER_SUCCEED = 'POST_PHONENUMBER_SUCCEED'

export const POST_NAMEDIV = 'POST_NAMEDIV' //医生端我的姓名修改提交
export const POST_NAMEDIV_SUCCEED = 'POST_NAMEDIV_SUCCEED'

//患者新闻
export const GET_PATIENT_BASIC_NEWS_LIST= 'GET_PATIENT_BASIC_NEWS_LIST'
export const PATIENT_BASIC_NEWS_LIST_ARRIVED='PATIENT_BASIC_NEWS_LIST_ARRIVED' //

export const GET_PATIENT_MESSAGE_NEWS_LIST= 'GET_PATIENT_MESSAGE_NEWS_LIST'
export const PATIENT_MESSAGE_NEWS_LIST_ARRIVED='PATIENT_MESSAGE_NEWS_LIST_ARRIVED' //

export const GET_PATIENT_NEWS_DETAIL= 'GET_PATIENT_NEWS_DETAIL'
export const PATIENT_NEWS_DETAIL_ARRIVED='PATIENT_NEWS_DETAIL_ARRIVED' //

//医生新闻
export const GET_DOCTOR_BASIC_NEWS_LIST= 'GET_DOCTOR_BASIC_NEWS_LIST'
export const DOCTOR_BASIC_NEWS_LIST_ARRIVED='DOCTOR_BASIC_NEWS_LIST_ARRIVED' //

export const GET_DOCTOR_MESSAGE_NEWS_LIST= 'GET_DOCTOR_MESSAGE_NEWS_LIST'
export const DOCTOR_MESSAGE_NEWS_LIST_ARRIVED='DOCTOR_MESSAGE_NEWS_LIST_ARRIVED' //

export const GET_DOCTOR_NEWS_DETAIL= 'GET_DOCTOR_NEWS_DETAIL'
export const DOCTOR_NEWS_DETAIL_ARRIVED='DOCTOR_NEWS_DETAIL_ARRIVED' //

// 医生端教育宣讲
export const GET_DOCTOR_TEACH_MESSAGE = 'GET_DOCTOR_TEACH_MESSAGE'
