import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Route, Switch} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import MyView from './MyView';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import HomeIcon from '@material-ui/icons/Home';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PersonIcon from '@material-ui/icons/Person';
import CropFreeIcon from '@material-ui/icons/CropFree';

import {noticeAll} from "../redux/actions/custom_action";
import IndexView from './IndexVeiw';

//医生端主页面
class MainView extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         bottomValue: 0,
         rows: [],
         showHome: null
      };

   }

   h
   componentDidMount() {
     
   }

   componentWillUnmount() {

   }

   //fired when props changed
   componentWillReceiveProps(nextProps) {
      if (nextProps.serverInfo != this.props.serverInfo) {

      }
   }

   setBottomMenuIndex(newValue) {
      this.setState({
         bottomMenuIndex: newValue,
      })
   }


   

   render() {
      //get react-material css styles
      const {classes} = this.props;

      return (
         <Switch>
            {/*<Route path={`${this.props.match.path}/chatWindow`} component={ChatWindow}/>*/}
            <React.Fragment >
               <Grid container className={classes.bodyDiv}>
               {/* 患者康复 健康知识 咨询医生 我的 */}
               {(() => {
                     switch (this.state.bottomValue) {
                        // 服药康复页
                        case 0:
                           return <IndexView history={this.props.history} match={this.props.match}/>;
                        // 健康知识页
                        case 1:
                           //return <AddPatient history={this.props.history} match={this.props.match}/>;
                           this.state.bottomValue = 0
                           const path = `/doctorWorkJump`; 
                           const params = {};
                           this.props.history.push(path, params);
                           //window.location.href = path;
                           //return
                        // 咨询医生页
                        //case 2:
                        //   return <ChatWindow history={this.props.history} match={this.props.match}/>;
                        // 我的
                        case 2:
                           return <MyView history={this.props.history} match={this.props.match}/>;
                        // 服药康复页
                        default:
                           return <IndexView/>;
                     }
                  }
               )()}
               </Grid>
               {/* 四个主菜单 */}

               <BottomNavigation
                  value={this.state.bottomValue}
                  onChange={(event, newValue) => {
                     this.setState({bottomValue: newValue,})
                  }}
                  showLabels
                  className={classes.footer}
               >
                  <BottomNavigationAction label="首页" icon={<HomeIcon  />} />
                  <BottomNavigationAction label="" icon={<CropFreeIcon />} />
                  <BottomNavigationAction label="我的" icon={<PersonIcon />} />
               </BottomNavigation>


               
            </React.Fragment>
         </Switch>
      );
   }

}


MainView.propTypes = {
   urlPath: PropTypes.object.isRequired,
   btnType: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => {
   return {}
}

const mapDispatchToProps = (dispatch) => {
   return {
      login: (userInfo) => {
         // dispatch(loginRequest(userInfo))
      },
   }
}


//css region
const styles = theme => ({
   rootDiv: {
      background: '#ffffff',

   },
   bodyDiv: {
      width: '100vw',
      height: '92vh',
      backgroundColor: '#F2F2F2',
   },
   footer: {
      //height: '8vh',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
   },

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainView));
