import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { postFirstFollowUp, queryFollowUpBasicInfo } from '../redux/actions/patient_manage_action';
import { Route, Switch } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axxxx from '../assets/images/axxxx.png';
import Input from '@material-ui/core/Input';
import SignaturePad from '../lib/signature/index';
import SignPadPlus from '../lib/signPadPlus';
import Toast from "antd-mobile/lib/toast";
import Moment from 'moment';
import Picker from "antd-mobile/lib/picker";
//弹出框

import { Upload, message, Icon } from 'antd';

// 引入antd 时间组件
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import '../assets/css/border.css';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import Checkbox from "@material-ui/core/Checkbox/Checkbox";
let jhjCheckResultIdDefaultValue;
let sputumCheckResultIdDefaultValue;
let patientTypeIdDefaultValue;
let patientTreatTypeIdDefaultValue;


const BeautyFormControlLabel = withStyles({
    root: {
        margin: 0,
    },
    label: {
        fontSize: '1rem',
        marginLeft: '-1.8vw',
        color: 'black',
        fontWeight: 395,
    },
})(FormControlLabel);

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 14,
        height: 14,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 14,
            height: 14,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

const GreenCheckbox = withStyles({
    root: {
        color: "#00C9E4",
        "&$checked": {
            color: "#00C9E4"
        }
    },
    checked: {}
})(props => <Checkbox color="default" {...props} />);

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

// 住址信息选择器
// 如果不是使用 List.Item 作为 children
const CustomChildren = props => (
    <div onClick={props.onClick} style={{paddingLeft: 15}}>
        <div
            className="test"
            style={{display: "flex", height: "13.3vw", lineHeight: "13.3vw"}}
        >
            <div
                style={{
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                }}
            >
                {props.children}
            </div>
            <div style={{textAlign: "right", color: "#888"}}>{props.extra}</div>
        </div>
    </div>
);

//问诊表
class ScreeningAskEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };

        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    componentDidMount() {
        this.state.patientId = this.props.location.state?.patientId;
        this.setState({
            name: this.props.location.state?.name,
            sex: this.props.location.state?.sex,
            age: this.props.location.state?.age,
            schoolName: this.props.location.state?.schoolName,
            hasTuberculosis: this.props.location.state?.askData?.hasTuberculosis,
            hasContactTuberculosis: this.props.location.state?.askData?.hasContactTuberculosis,
            hasCoughWeek: this.props.location.state?.askData?.hasCoughWeek,
            hasCoughBlood: this.props.location.state?.askData?.hasCoughBlood,
            hasChestPain: this.props.location.state?.askData?.hasChestPain,
            hasRepeatFever: this.props.location.state?.askData?.hasRepeatFever,
            hasSweat: this.props.location.state?.askData?.hasSweat,
            hasLostWeight: this.props.location.state?.askData?.hasLostWeight,
            hasWeak: this.props.location.state?.askData?.hasWeak,
            hasIssueOthers: this.props.location.state?.askData?.hasIssueOthers,
            hasEpidemic: this.props.location.state?.askData?.hasEpidemic,
            hasConjunctivitis: this.props.location.state?.askData?.hasConjunctivitis,
            hasTympanitis: this.props.location.state?.askData?.hasTympanitis,
            hasSkinDisease: this.props.location.state?.askData?.hasSkinDisease,
            hasAllergic: this.props.location.state?.askData?.hasAllergic,
            hasOtherTaboo: this.props.location.state?.askData?.hasOtherTaboo,
        });
        console.log(this.props.location.state)

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.createFirstFollowUpRet !== this.props.createFirstFollowUpRet) {
            //获得发送结果之后，再调用路由切入对应页面
            if (nextProps.createFirstFollowUpRet.code == 200) {
                Toast.show("更新成功")
                this.props.history.goBack();
            }
            if (nextProps.createFirstFollowUpRet.code == 201) {
                Toast.show("更新失败！" + nextProps.createFirstFollowUpRet.msg)
            }
        }
    }

    


    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });

    }


    checkoutEventFirst(event) {
        if (event.target.checked == true) {
            this.state.firstArrayCheck.push(event.target.value);
        }
        if (event.target.checked == false) {
            let numberInit = this.state.firstArrayCheck.indexOf(event.target.value);
            this.state.firstArrayCheck.splice(numberInit, 1);
        }

        if (this.state.firstArrayCheck.indexOf("11") >= 0) {
            this.setState({
                hideOther: 0
            })
        } else {
            this.setState({
                hideOther: 1
            })
        }
    }
    checkoutDrugType(event) {
        console.log(event)
        console.log(this.state.drugTypeArrayCheck)
        if (event.target.checked == true) {
            this.state.drugTypeArrayCheck.push(event.target.value);
        }
        if (event.target.checked == false) {
            let numberInit = this.state.drugTypeArrayCheck.indexOf(event.target.value);
            this.state.drugTypeArrayCheck.splice(numberInit, 1);
        }
        this.setState({
            drugTypeStr: this.state.drugTypeArrayCheck.join(",")
        })
    }


    //点击更新按钮
    handleSubmit() {
       

        //将数据打包成json,然后调用dispatch将数据发送出去
        const followUpInfo = {
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
            patientId: this.state.patientId,
            hasTuberculosis: this.state.hasTuberculosis,
            hasContactTuberculosis: this.state.hasContactTuberculosis,
            hasCoughWeek: this.state.hasCoughWeek,
            hasCoughBlood: this.state.hasCoughBlood,
            hasChestPain: this.state.hasChestPain,
            hasRepeatFever: this.state.hasRepeatFever,
            hasSweat: this.state.hasSweat,
            hasLostWeight: this.state.hasLostWeight,
            hasWeak: this.state.hasWeak,
            hasIssueOthers: this.state.hasIssueOthers,
            hasEpidemic: this.state.hasEpidemic,
            hasConjunctivitis: this.state.hasConjunctivitis,
            hasTympanitis: this.state.hasTympanitis,
            hasSkinDisease: this.state.hasSkinDisease,
            hasAllergic: this.state.hasAllergic,
            hasOtherTaboo: this.state.hasOtherTaboo,
        };
        //提交首次随访
        this.props.addFirstFollowUp(followUpInfo);

        /*
        const path = '/doctorIndex/manage/PatientFeedback';   // 跳转到反馈单
        var params = {
          findPerson: 1,
          patientId: this.props.location.state.patientId,
          followUpId:this.props.location.state.followUpId,
          patientNoticeId: this.props.followUpBasic.patientNoticeId
        }
       this.props.history.push(path, params);
       */
    }

    //点击返回
    handleNavBack() {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;

       
        //this.state.name = '苏非'
        //this.state.age = 23
        //this.state.sex=2
        return (
            <Switch>
                {/*<Route path={`${this.props.match.path}/paper5`} component={FollowUpVisitPaper5} />*/}
                <div className={classes.root}>
                    <Grid item xs={12} className={classes.Patient}>
                        <img src={axxxx} alt="" className={classes.jiantou} onClick={this.handleNavBack}></img>
                        <Grid item xs={12} className={classes.headers}><h5 className={classes.huanzhe}>问诊表</h5></Grid>
                    </Grid>
                    <Grid className={classes.roots}>
                        <Typography h5 noWrap className={classes.spacemargin}></Typography>
                        <Grid container justify="center" wrap="nowrap" spacing={2}>
                            <Typography className={classes.titleMain} h5 gutterBottom>
                                问诊表
                            </Typography>
                        </Grid>
                        <Typography h5 noWrap className={classes.spacemargin}></Typography>
                        <Grid container justifyContent="center" >
                    <Grid item xs={12}>
                        <Typography className={classes.hospital} color="textSecondary">
                            <span>&nbsp;&nbsp;学校：{this.state.schoolName}</span>
                        </Typography>
                    </Grid>

                </Grid>
                <div className={classes.space}> </div>


                <Grid container justifyContent="flex-start" >
                    <Grid item xs={3}>
                        <Typography className={classes.title} gutterBottom>&nbsp;&nbsp;姓名：</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Input name="name" value={this.state.name} className={classes.inputFull2}
                            onChange={this.handleInputChange}/>
                    </Grid>
                    {/*
                    <Grid item xs={2}>
                        <Typography className={classes.title} gutterBottom>&nbsp;&nbsp;体温：</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Input name="temperature" value={this.state.temperature} className={classes.input}
                            onChange={this.handleInputChange} />
                    </Grid>
                    <Grid item xs={2}>
                        ℃
                    </Grid>
                    */}
                    
                </Grid>
                <div className={classes.space}> </div>
                <Grid container justifyContent="flex-start" >
                    <Grid item xs={3}>
                        <Typography className={classes.title} gutterBottom>&nbsp;&nbsp;姓别：</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container justifyContent="flex-start" >
                            <RadioGroup row defaultValue="" name="sex" value={this.state.sex} onChange={this.handleInputChange}>
                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="男" checked={this.state.sex==1}/>
                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="女" checked={this.state.sex==2}/>
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.title} gutterBottom>&nbsp;&nbsp;年龄：</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Input name="age" value={this.state.age} className={classes.input}
                             onChange={this.handleInputChange}/>
                    </Grid>
                </Grid>
       

                <div className={classes.space}> </div>
                <div className={classes.space}> </div>
                <div className={classes.space}> </div>


                <div className={classes.ask}>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 1、既往结合病史（含肺外结核）：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasTuberculosis" value={this.state.hasTuberculosis}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasTuberculosis==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasTuberculosis==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>


                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 2、家庭成员或经常接触的亲戚朋友中、原同班师生或同宿舍学生有无结核病患者：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasContactTuberculosis" value={this.state.hasContactTuberculosis}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasContactTuberculosis==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasContactTuberculosis==0}/>
                        </RadioGroup>
                    </Grid>
                    
                    <div className={classes.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 3、连续咳嗽或咳痰超过2周：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasCoughWeek" value={this.state.hasCoughWeek}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasCoughWeek==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasCoughWeek==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 4、咯血或血痰：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasCoughBlood" value={this.state.hasCoughBlood}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasCoughBlood==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasCoughBlood==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 5、胸痛、胸闷及气短：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasChestPain" value={this.state.hasChestPain}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasChestPain==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasChestPain==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 6、反复低热：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasRepeatFever" value={this.state.hasRepeatFever}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasRepeatFever==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasRepeatFever==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>


                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 7、盗汗：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasSweat" value={this.state.hasSweat}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasSweat==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasSweat==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 8、消瘦或体重下降：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasLostWeight" value={this.state.hasLostWeight}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasLostWeight==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasLostWeight==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 9、乏力、食欲减退：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasWeak" value={this.state.hasWeak}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasWeak==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasWeak==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 10、其他肺结核可疑症状：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasIssueOthers" value={this.state.hasIssueOthers}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasIssueOthers==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasIssueOthers==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>
                    <Grid container justifyContent="flex-start" >
                        <Grid item xs={12}>
                            <Typography className={classes.title} style={{ textAlign: 'left' }} gutterBottom>如有,请输入其他症状详细内容：</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <Grid item xs={12}>
                            <Input name="issueOthers" value={this.state.issueOthers} className={classes.inputFull}
                                onChange={this.handleInputChange} />
                        </Grid>
                    </Grid>

                    <div className={classes.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 11、急性传染病（如麻疹、百日咳、流行性感冒、肺炎等）：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasEpidemic" value={this.state.hasEpidemic}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasEpidemic==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasEpidemic==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 12、急性眼结膜炎：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasConjunctivitis" value={this.state.hasConjunctivitis}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasConjunctivitis==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasConjunctivitis==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>


                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 13、急性中耳炎：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasTympanitis" value={this.state.hasTympanitis}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasTympanitis==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasTympanitis==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 14、全身性皮肤病：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasSkinDisease" value={this.state.hasSkinDisease}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasSkinDisease==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasSkinDisease==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 15、过敏体质：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasAllergic" value={this.state.hasAllergic}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasAllergic==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasAllergic==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.textDiv}> 16、判定不适合进行皮肤试验的其他情况：  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="0" name="hasOtherTaboo" value={this.state.hasOtherTaboo}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasOtherTaboo==1}/>
                            <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasOtherTaboo==0}/>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.space}> </div>
                    <Grid container justifyContent="flex-start" >
                        <Grid item xs={12}>
                            <Typography className={classes.title} style={{ textAlign: 'left' }} gutterBottom>如有,请输入其他情况详细内容：</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <Grid item xs={12}>
                            <Input name="otherTaboo" value={this.state.otherTaboo} className={classes.inputFull}
                                onChange={this.handleInputChange} />
                        </Grid>
                    </Grid>

                    <div className={classes.space}> </div>
                    <div className={classes.space}> </div>
                    
                    {/*
                    <Grid container justifyContent="flex-start" >

                        <Grid item xs={3}>
                            <Typography className={classes.title} gutterBottom>患者/家属：</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Input name="signName" value={this.state.signName} className={classes.input}
                                onChange={this.handleInputChange} />
                        </Grid>

                        
                        <Grid item xs={4}>
                            <Typography className={classes.title} gutterBottom>填表日期：</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <DatePicker format="YYYY-MM-DD" suffixIcon=" " locale={locale}
                                defaultValue={this.state.addTime} onChange={this.addTimePickerOnChange}
                                allowClear={false} placeholder="请选择日期" className="date-picker-border"
                            />
                        </Grid>


                    </Grid>
                    <div className={classes.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography variant="h6" style={{color:"red"}}> 重要提示：《传染病防治法》规定隐瞒疫区旅游史或接触史者要承担相应法律责任！  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                            <RadioGroup row name="read" value={this.state.read}  onChange={this.handleInputChange}>
                                <BeautyFormControlLabel label="已详细阅读重要提示，填写信息确保真实无误" control={<GreenCheckbox  value="1" color="primary" onChange={this.checkoutEventFirst} />}/>
                            </RadioGroup>
                        </Typography>
                    </Grid>
                    <div className={classes.space}> </div>
                    <div className={classes.space}> </div>
                    */}
                </div>



                        <Grid container justify="center" wrap="nowrap" spacing={2}>
                            <Button variant="contained" color="primary" className={classes.buttons} onClick={this.handleSubmit}>更&nbsp;&nbsp;&nbsp;新</Button>
                        </Grid>
                    </Grid>
                </div>
            </Switch>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        followUpBasic: state.patientManageReducer.followUpBasicInfo,
        fileUrl: state.patientManageReducer.fileUrl,
        followupId: state.patientManageReducer.firstFollowUpId,
        createFirstFollowUpRet: state.patientManageReducer.createFirstFollowUpRet,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //查询随访基本信息
        queryFollowUpBasicInfo: (patientId) => {
            dispatch(queryFollowUpBasicInfo(patientId))
        },
        //提交日常随访信息
        addFirstFollowUp: followUpInfo => {
            dispatch(postFirstFollowUp(followUpInfo))
        }
    }
}


//css region
const styles = theme => ({
    // 时间组件样式
    titleBoldBox: {
        marginLeft: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333',
        display: 'flex',
        // justifyContent: 'center'
    },
    pickersInputBorder: {
        border: 0,
    },
    timeContentBoxRight: {
        width: "60%"
    },
    textFieldContent: {
        width: '60vw'
    },
    /**************** 分割线 **************/
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        background: '#ffffff',
        with: '100%',
        lineHeight: '2rem'
    },

    roots: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },

    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },

    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },

    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },

    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },

    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },

    file: {
        display: 'none',
        visibility: 'hidden',
    },

    buttonsTwo: {
        width: '25vw',
        height: '8vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '3vw',
        marginBottom: 'theme.spacing(2)',

        margin: theme.spacing(1),
        position: 'relative',
        border: '1px solid #00C9E4',
        borderRadius: '5px',
        padding: '4px 12px',
        overflow: 'hidden',
        color: '#fff',
        textDecoration: 'none',
        lineHeight: '8vw'
    },

    buttons: {
        width: '95vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginBottom: '10vw',
        fontSize: '4.5vw',
        marginTop: '5vw'
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },

    textField: {
        marginTop: theme.spacing(0),
        width: '90vw',
    },

    input: {
        margin: theme.spacing(0),
        width: '11vw'
    },
    inputFull: {
        margin: theme.spacing(0),
        width: '100%',
    },


    inputs: {
        width: '90vw',
        marginBottom: theme.spacing(2),
    },

    rootDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
    },

    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },

    title1: {
        color: '#000000',
        fontSize: '4vw',
        fontWeight: '500',
    },

    span: {
        float: 'right'
    },

    titleBold: {
        marginLeft: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333'
    },

    titleButton: {
        marginLeft: theme.spacing(10),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },

    titleBoldDiv: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },

    titleTime: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333'
    },

    titleMain: {
        fontSize: '5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },

    titleBolds: {
        marginBottom: 0,
        fontSize: '4.2vw',
        lineHeight: '8vw',
        color: '#333333'
    },

    title: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '500',
        color: '#333333'
    },

    margin: {
        margin: theme.spacing(2),
    },

    spacemargin: {
        margin: theme.spacing(2),

    },

    titleBoldss: {
        marginTop: theme.spacing(2),
        fontSize: '4.2vw',
        color: '#333333'

    },

    doctor: {
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '700',
        color: '#333333'
    },

    inputName: {
        marginBottom: theme.spacing(1),
        fontWeight: '500',
        width: '67vw'
    },

    photosDIV: {
        marginBottom: theme.spacing(2),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },
    hideMe: {
        display: 'none'
    },
    dataDiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "90vw",
        height: "13.3vw",
        fontSize: "4.2vw",
    },
    space: {
        height: '5px',
        margin: '5px',
    },
    ask: {
        padding: '1.5rem',
    },

});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScreeningAskEdit));
