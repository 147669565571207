//服药康复页面
import React,{Component,Fragment,useState } from 'react'
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Route, Switch} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AccessAlarm from '@material-ui/icons/AccessAlarm';
import MyMedRecord from './medicineWidget/MyMedRecord';
import {postFollowUpVisitPaper1,querySputumCheckRecord,postTakeMedicineEvent,getTakeMedicineStatus} from '../redux/actions/patient_manage_action';
import Resistant from "./../doctor/common/resistant";
import RemindList from "./medicineWidget/RemindList";
import Moment from "moment";
import Toast from "antd-mobile/lib/toast";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { IconButton } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from '@material-ui/core';
import QRCode from 'qrcode.react';

import defaultPhoto from '../assets/images/head.png';


const images = [
   'image1.jpg',
   'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAAGQAQAAAACoxAthAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAd2KE6QAAAAJcEhZcwAAFxEAABcRAcom8z8AAADiSURBVHja7dg7EsMgDAVA7n/ppMgo+uCM45pVY2xp6d7AeL0e10IQBEGQv8hq9Xn/DowegiAlQKuOXvUQBOkjGbSLHoIgq4/kdwRB7iLWGYIglUTlev3oIcjppFY9ivYegpxO9trjVXoIcjyZV7VczS0QBKkjSevhhCDIjFBc1PbVuMIhyKFkldqH51cEOZt01OLU1giC7BWD9YkgyPUv6GA1YoUiyOFkj9eE5Q1BjicRoR6qDhEE2UkM5BYtaAiCjIhNWqKGIMeTqDx+YosOEASpFQNJchsEQZ4VgiAIgtzWG2V5sfGmJdwUAAAAAElFTkSuQmCC',
   'image3.jpg',
   // 添加更多图片
 ];

const columns = [
    {id: 'actualTime', label: '就诊日期', minWidth: 40, align: 'center'},
    {id: 'orderTime', label: '预约日期', minWidth: 50, align: 'center'},
];

const QRCodeGenerator = ({ value, size }) => {
   if (!value) {
     return null; // 不渲染组件
   }
 
   return (
     <div>
       <QRCode value={value} size={size} />
     </div>
   );
 };

//学生二维码界面
class QrCode extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         userId: localStorage.getItem("userId"),
         patientId: localStorage.getItem("patientId"),
         students: [],
         styleBtnBackground: "#83E78A",

         currentImage: 0,
         prevImage: 0,
      };


      this.handlePrevious = this.handlePrevious.bind(this);
      this.handleNext = this.handleNext.bind(this);
      this.handleLinkClick = this.handleLinkClick.bind(this);

      this.handleScreenClick = this.handleScreenClick.bind(this);
      this.handleCheckClick = this.handleCheckClick.bind(this);
      this.editStudent = this.editStudent.bind(this);
   }

   componentDidMount() {
      //this.props.getSputumRecord(this.state.patientId);
      this.props.getTakeMedicineStatusFun();//直接使用之前的接口吧

      //测试数据
      this.setState({
         //students: [{"qrcode":"aaa","photo":null,"id":111,"screenStatus":0,"checkStatus":1,"name":"小红","idNumber":"123"},{"qrcode":"bbb","photo":images[1],"id":222,"screenStatus":1,"checkStatus":1,"name":"小如","idNumber":"456"},{"qrcode":"ccc","photo":"","id":333,"screenStatus":0,"checkStatus":0,"name":"小昭","idNumber":"789"}]
      })
   }

   componentWillUnmount() {
      
   }


   //点击添加提醒
   handleReminder() {
      const path = `${this.props.match.path}/remindlist`;
      this.props.history.push(path);
   }

   //fired when props changed
   handleChange(event, newValue) {
      this.setState({value: newValue});
   };

   handleChangeIndex(index) {
      this.setState({index: index});
   }


   //fired when props changed
   componentWillReceiveProps(nextProps) {
      if(this.props.getTakeMedicineStatus != nextProps.getTakeMedicineStatus){
         console.log("result->"+nextProps.getTakeMedicineStatus)
         console.log(nextProps.getTakeMedicineStatus)
         
         if(nextProps.getTakeMedicineStatus.code == 200){
            this.setState({
               students: nextProps.getTakeMedicineStatus?.data?.students
            });
            if(nextProps.getTakeMedicineStatus?.data?.students == null || nextProps.getTakeMedicineStatus?.data?.students.length < 1){
               //跳转到添加人员页面
               const path = `/patient/add`; 
               const params = {};
               this.props.history.push(path, params);
               return;
            }
         }
         if(nextProps.getTakeMedicineStatus.code == 201){
            Toast.show("加载信息失败！"+nextProps.getTakeMedicineStatus.msg)
         }
      }

   }

   handleCheckClick() {
      const pos = this.state.currentImage;
      if(this.state.students[pos].checkStatus === 1){
         const path = `/patient/check`;
         const params = {
            "patientId": this.state.students[pos].id
         }
         this.props.history.push(path,params);
      }
   }

   handleScreenClick() {
      const pos = this.state.currentImage;
      if(this.state.students[pos].screenStatus == 1){
         const path = `/patient/askEdit`;
         const params = {
            "patientId": this.state.students[pos].id,
            "name":this.state.students[pos].name,
            "age":this.state.students[pos].age,
            "sex":this.state.students[pos].sex,
            "schoolName":this.state.students[pos].schoolName,
            "askData":this.state.students[pos].askData,
         }
         console.log(params)
         this.props.history.push(path,params);
      }else{
         const path = `/patient/ask`;
         const params = {
            "patientId": this.state.students[pos].id,
            "name":this.state.students[pos].name,
            "age":this.state.students[pos].age,
            "sex":this.state.students[pos].sex,
            "schoolName":this.state.students[pos].schoolName,
         }
         this.props.history.push(path,params);
      }
   }

   handleMyMedRecord() {
      const path = `${this.props.match.path}/mymedrecord`;
      this.props.history.push(path);
   }

   // 卡片中的未服点击事件
   handleReviseClick() {
      if(this.props.getTakeMedicineStatus == 0){
         this.props.takeMedicineEvent(this.state.userId);
         /*
         this.setState({
            hasTakeMedicine: 1,//'已服'
            styleBtnBackground: '#FFD700'
         });
         window.location.reload();
         */
      }
   }

   handlePrevious() {
      console.log("pre")
      if(this.state.students.length < 2) return;
      this.setState({
         currentImage: this.state.currentImage === 0 ? this.state.students.length - 1 : this.state.currentImage - 1,
      }) 
      console.log(this.state.currentImage)
   }

   handleNext() {
      console.log("next")
      if(this.state.students.length < 2) return;
      //setCurrentImage((this.state.prevImage) => (this.state.prevImage === images.length - 1 ? 0 : this.state.prevImage + 1));
      
      this.setState({
         currentImage:this.state.currentImage === this.state.students.length - 1 ? 0 : this.state.currentImage + 1
      })
      console.log(this.state.currentImage)
   }


   handleLinkClick() {
      // 处理超链接点击事件
      console.log('超链接被点击了');
    };

   editStudent(){
      if(this.state.students?.length <= 0) return;
      const path = `/editPatient`;
      const params = {
         patientId: this.state.students[this.state.currentImage]?.id
      }
      this.props.history.push(path,params)
   }
   

   render() {
      //get react-material css styles
      const {classes, sputumRecord,getTakeMedicineStatus} = this.props;
      if(getTakeMedicineStatus){
         this.state.hasTakeMedicine = getTakeMedicineStatus;
         this.state.styleBtnBackground = getTakeMedicineStatus > 0 ? '#FFD700' : "#83E78A";
      }

      if(window.location.search && window.location.search !== null && window.location.search !== ''){
         this.state.displayNone = window.location.search.split("flag=")[1]+'';
      }


      let qrValue = this.state.students[this.state.currentImage]?.qrcode

      return (
         <Switch>
            <Route path={`${this.props.match.path}/mymedrecord`} component={MyMedRecord}/>
            <Route path={`${this.props.match.path}/remindlist`} component={RemindList}/>

            <Grid container spacing={0} className={classes.root}>
               {/* header */}
               <Grid className={classes.headerDiv} container item xs={12} direction='conlumn'>
                  {/*<h4 className={classes.defaultKang}>二维码</h4>*/}
                  <Grid item xs={3} ></Grid>
                  <Grid item xs={6} ><h4 className={classes.defaultKang}>二维码</h4></Grid>
                  <Grid item xs={3} ><Link className={classes.next} onClick={this.editStudent}><h5>编辑</h5></Link> </Grid>
               </Grid>

               <div className={classes.bodyDiv}>
               {/*二维码 */}
               <Grid container spacing={0} alignItems="center" className={classes.qrCodeDiv} item xs={12} justify="center" alignContent="center">
                  <Grid item xs={2} container  justifyContent="flex-end">
                     <IconButton color="primary" onClick={this.handlePrevious}>
                        <NavigateBeforeIcon />
                     </IconButton>
                  </Grid>
                  <Grid item xs={8} className={classes.imageContainer}>
                     {/*<img src={images[this.state.currentImage]} alt="Gallery" className={classes.imageBox} />*/}
                     <QRCodeGenerator value={this.state.students[this.state.currentImage]?.qrcode} className={classes.qrCode} size={185}/>
                  </Grid>
                  <Grid item xs={2}>
                     <IconButton color="primary" onClick={this.handleNext}>
                        <NavigateNextIcon />
                     </IconButton>
                  </Grid>
               </Grid>

               <div className={classes.spacing20}></div>
               <div className={classes.spacing10}></div>
               <div className={classes.dashedLine}></div>

               {/*头像 */}
               <Grid container spacing={0} alignItems="center" className={classes.qrCodeDiv} item xs={12} justify="center" alignContent="center">
                  <Grid item xs={3} container  justifyContent="flex-end">
                  </Grid>
                  <Grid item xs={5} className={classes.imageContainer}>
                     <img src={this.state.students[this.state.currentImage]?.photo == null?defaultPhoto:this.state.students[this.state.currentImage]?.photo} alt="Gallery" className={classes.imageBox} />
                  </Grid>
                  <Grid item xs={3}>
                  </Grid>
               </Grid>

               <Grid container spacing={0} alignItems="center" item xs={12} alignContent="center">
                  <Grid item xs={3} container  justifyContent="flex-end">
                  </Grid>
                  <Grid item xs={8}>
                     <div className={classes.infoContainer}>
                        <Typography variant="subtitle1" className={classes.label}>
                           筛查信息:
                        </Typography>
                        <Link component="button" variant="body1" onClick={this.handleScreenClick} className={classes.link}>
                           {this.state.students[this.state.currentImage]?.screenStatus == 1?'查看':'新增'}
                        </Link>
                     </div>
                  </Grid>
                  <Grid item xs={1}>
                  </Grid>
               </Grid>
               <Grid container spacing={0} alignItems="center" item xs={12} alignContent="center">
                  <Grid item xs={3} container  justifyContent="flex-end">
                  </Grid>
                  <Grid item xs={8}>
                     <div className={classes.infoContainer}>
                        <Typography variant="subtitle1" className={classes.label}>
                           化验信息:
                        </Typography>
                        <Link component="button" variant="body1" onClick={this.handleCheckClick} className={classes.link}>
                           {this.state.students[this.state.currentImage]?.checkStatus == 1?'查看':'无'}
                        </Link>
                     </div>
                  </Grid>
                  <Grid item xs={1}>
                  </Grid>
               </Grid>
               <Grid container spacing={0} alignItems="center" item xs={12} alignContent="center">
                  <Grid item xs={3} container  justifyContent="flex-end">
                  </Grid>
                  <Grid item xs={8}>
                     <div className={classes.infoContainer}>
                        <Typography variant="subtitle1" className={classes.label}>
                           学生姓名:
                        </Typography>
                        <Typography variant="subtitle1" className={classes.label}>
                           {this.state.students[this.state.currentImage]?.name}
                        </Typography>
                     </div>
                  </Grid>
                  <Grid item xs={1}>
                  </Grid>
               </Grid>
               <Grid container spacing={0} alignItems="center" item xs={12} alignContent="center">
                  <Grid item xs={3} container  justifyContent="flex-end">
                  </Grid>
                  <Grid item xs={8}>
                     <div className={classes.infoContainer}>
                        <Typography variant="subtitle1" className={classes.label}>
                           身份证号:
                        </Typography>
                        <Typography variant="subtitle1" className={classes.label}>
                           {this.state.students[this.state.currentImage]?.idNumber}
                        </Typography>
                     </div>
                  </Grid>
                  <Grid item xs={1}>
                  </Grid>
               </Grid>
               </div>
               

               
            </Grid>
         </Switch>
      );
   }

}

const mapStateToProps = (state) => {
   return {
      serverInfo: state.serverInfo,
      loggedIn: state.loggedIn,
      sputumRecord: state.patientManageReducer.sputumRecord,
      ConfirmationManagement: state.patientManageReducer.ConfirmationManagement,
      takeMedicineAdmin: state.patientManageReducer.takeMedicineAdmin,
      getTakeMedicineStatus: state.patientManageReducer.getTakeMedicineStatus
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      //预约查痰记录
      getSputumRecord: (patientId) => {
         dispatch(querySputumCheckRecord(patientId))
      },

      // 治疗方案
      postFollowUpVisitPaper1: (patientId) => {  //定义的函数
         dispatch(postFollowUpVisitPaper1(patientId)) // action里面定义的
      },

      // 点击服药
      takeMedicineEvent: (userId)=>{
         dispatch(postTakeMedicineEvent(userId))
      },

      // 查询服药状态
      getTakeMedicineStatusFun: (userId)=>{
         dispatch(getTakeMedicineStatus(userId))
      }
   };
};


//css region
const styles = theme => ({
   root: {
      display: 'block',
      //flexGrow: 1,
      //height: '100vh'
      //padding: theme.spacing(0, 1),
   },
   headerDiv: {
      display: 'flex',
      width: '100vw',
      height: '14vw',
      lineHeight: '14vw',
      textAlign: 'center',
      background: 'linear-gradient(to left,#00c9e4, 50%, #00dfd7)',
      color: '#ffffff',
   },
   defaultKang: {
      color: '#ffffff'
   },

   spacing5: {
      height: '5px'
   },
   spacing10: {
      height: '10px'
   },
   spacing20: {
      height: '20px'
   },
   
   qrCodeDiv: {
      //flexGrow: 1,
      height: '30vh',
      padding: theme.spacing(2),
    },
    imageContainer: {
      textAlign: 'center',
      width: '100%',
      height: '100%',
    },
    qrCode: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      padding: '10px',
      backgroundColor: '#ffffff',
    },
    imageBox: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    dashedLine: {
      width: '80%',
      margin: '0 auto',
      borderTop: `1px dashed ${theme.palette.text.primary}`,
    },
    infoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(0),
    },
    label: {
      marginRight: theme.spacing(2),
      fontSize: '14px',
      fontWeight: 'bold',
    },
    link: {
      marginRight: theme.spacing(2),
      fontSize: '14px',
    },

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(QrCode));
