import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { addPatientInfo, postFirstFollowUp, queryFollowUpBasicInfo } from '../redux/actions/patient_manage_action';
import { Route, Switch } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axxxx from '../assets/images/axxxx.png';
import Input from '@material-ui/core/Input';
import SignaturePad from '../lib/signature/index';
import SignPadPlus from '../lib/signPadPlus';
import Toast from "antd-mobile/lib/toast";
import Moment from 'moment';
import Picker from "antd-mobile/lib/picker";
//弹出框

import { Upload, message, Icon } from 'antd';

// 引入antd 时间组件
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import '../assets/css/border.css';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import { getClassList } from '../redux/actions';
let jhjCheckResultIdDefaultValue;
let sputumCheckResultIdDefaultValue;
let patientTypeIdDefaultValue;
let patientTreatTypeIdDefaultValue;


const BeautyFormControlLabel = withStyles({
    root: {
        margin: 0,
    },
    label: {
        fontSize: '1rem',
        marginLeft: '-1.8vw',
        color: 'black',
        fontWeight: 395,
    },
})(FormControlLabel);

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 14,
        height: 14,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 14,
            height: 14,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

const GreenCheckbox = withStyles({
    root: {
        color: "#00C9E4",
        "&$checked": {
            color: "#00C9E4"
        }
    },
    checked: {}
})(props => <Checkbox color="default" {...props} />);

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

// 住址信息选择器
// 如果不是使用 List.Item 作为 children
const CustomChildren = props => (
    <div onClick={props.onClick} style={{paddingLeft: 15}}>
        <div
            className="test"
            style={{display: "flex", height: "13.3vw", lineHeight: "13.3vw"}}
        >
            <div
                style={{
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                }}
            >
                {props.children}
            </div>
            <div style={{textAlign: "right", color: "#888"}}>{props.extra}</div>
        </div>
    </div>
);

//学生信息
class AddPatient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            sex: '',
            age: '',
            idNumber: '',
        };

        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
            hospitalId: localStorage.getItem("hospitalId"),
            hospitalName: localStorage.getItem("hospitalName"),
        })
        this.props.getClassList();

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.addPatientInfoResult !== this.props.addPatientInfoResult) {
            //获得发送结果之后，再调用路由切入对应页面
            if (nextProps.addPatientInfoResult.code == 200) {
                Toast.show("添加个人信息成功")

                //const path = `${this.props.match.path}/paper5`;
                //首次随访跳转到管理反馈通知单
                //this.props.history.push(path,{"followupId":this.props.followupId});

                const path = `/patientIndex`;   // 跳转到个人端主页
                var params = {
                    userId: this.state.userId,
                    addPatient: 1,
                }
                //this.props.history.push(path, params);
                window.location.href = path;

            }
            if (nextProps.addPatientInfoResult.code == 201) {
                Toast.show("添加失败！" + nextProps.addPatientInfoResult.msg)
            }
        }
        
    }

   


    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });

    }

   


    //点击提交按钮
    handleSubmit() {
        

        //将数据打包成json,然后调用dispatch将数据发送出去
        const patientInfo = {
            userId: this.state.userId,
            token: localStorage.getItem("token"),
            name: this.state.name,
            sex: this.state.sex,
            age: this.state.age,
            idNumber: this.state.idNumber,
            hospitalId: this.state.hospitalId,
            telphone: this.state.telphone,
            classIds: this.state.highRegionIds,
            remark: this.state.remark,
        };
        //提交首次随访
        this.props.addPatientInfo(patientInfo);

        /*
        const path = '/doctorIndex/manage/PatientFeedback';   // 跳转到反馈单
        var params = {
          findPerson: 1,
          patientId: this.props.location.state.patientId,
          followUpId:this.props.location.state.followUpId,
          patientNoticeId: this.props.followUpBasic.patientNoticeId
        }
       this.props.history.push(path, params);
       */
    }

    //点击返回
    handleNavBack() {
        this.props.history.goBack();
    }

    render() {
        const { classes, classResult } = this.props;
        

        //let allregion = classResult?.data?.allregion;//[{"id":null,"label":"测试省","value":34270,"parentId":1,"name":null,"population":null,"children":[{"id":null,"label":"测试市","value":34271,"parentId":34270,"name":null,"population":null,"children":[{"id":null,"label":"测试区","value":34272,"parentId":34271,"name":null,"population":null,"children":[{"id":null,"label":"测试乡","value":34273,"parentId":34272,"name":null,"population":null,"children":[{"id":null,"label":"测试村","value":34274,"parentId":34273,"name":null,"population":null,"children":null},{"id":null,"label":"测试村1","value":40244,"parentId":34273,"name":null,"population":null,"children":null},{"id":null,"label":"测试乡（村）","value":399909,"parentId":34273,"name":null,"population":null,"children":null}]}]}]}]}]
        //let allregion = [{"label":"数学系","value":2,"parentId":0,"children":[{"label":"数学系2013级","value":3,"parentId":2,"children":[{"label":"二班","value":7,"parentId":3,"children":null}]}]},{"label":"计算机系","value":4,"parentId":0,"children":[{"label":"计算机2013级","value":5,"parentId":4,"children":[{"label":"一班","value":6,"parentId":5,"children":null}]}]}];
        this.state.showFace = 1
        return (
            <Switch>
                {/*<Route path={`${this.props.match.path}/paper5`} component={FollowUpVisitPaper5} />*/}
                <div className={classes.root}>
                    <Grid item xs={12} className={classes.Patient}>
                        <img src={axxxx} alt="" className={classes.jiantou} onClick={this.handleNavBack}></img>
                        <Grid item xs={12} className={classes.headers}><h5 className={classes.huanzhe}>学生信息表</h5></Grid>
                    </Grid>
                    <Grid className={classes.roots}>
                        <Typography h5 noWrap className={classes.spacemargin}></Typography>
                        <Grid container justify="center" wrap="nowrap" spacing={2}>
                            <Typography className={classes.titleMain} h5 gutterBottom>
                                学生信息表
                            </Typography>
                        </Grid>
                        <Typography h5 noWrap className={classes.spacemargin}></Typography>
                        <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    学校：<span className={classes.title}>{this.state.hospitalName}</span>
                                </Typography>
                            </Grid>

                        </Grid>
                        <hr className={classes.hr}></hr>
                        <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                    姓名：<span className={classes.title}></span>
                                    <Input name="name" value={this.state.name} onChange={this.handleInputChange} className={classes.inputName}
                                        inputProps={{ 'aria-label': 'description' }} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <hr className={classes.hr}></hr>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    性别：
                                </Typography>
                            </Grid>
                            <RadioGroup row name="sex" value={this.state.sex} onChange={this.handleInputChange}>
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="男" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="女" />
                            </RadioGroup>
                        </Grid>
                        <hr className={classes.hr}></hr>
                        <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                    年龄：<span className={classes.title}></span>
                                    <Input name="age" value={this.state.age} onChange={this.handleInputChange} className={classes.inputName}
                                        inputProps={{ 'aria-label': 'description' }} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <hr className={classes.hr}></hr>
                        <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                    电话：<span className={classes.title}></span>
                                    <Input name="telphone" value={this.state.telphone} onChange={this.handleInputChange} className={classes.inputName}
                                        inputProps={{ 'aria-label': 'description' }} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <hr className={classes.hr}></hr>
                        <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                    身份证号：<span className={classes.title}></span>
                                    <Input name="idNumber" value={this.state.idNumber} onChange={this.handleInputChange} className={classes.inputName}
                                        inputProps={{ 'aria-label': 'description' }} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <hr className={classes.hr}></hr>
                        <Grid className={classes.dataDiv}>
                            <Grid container alignItems="center" item xs={3}>选择班级：</Grid>
                            <Picker title="选择院系" extra="请选择" data={this.props.classResult?.data?.allregion} value={this.state.highRegionIds}
                                onChange={v => this.setState({highRegionIds: v})} onOk={this.handleHighRegionPickerOk} >
                                <CustomChildren></CustomChildren>
                            </Picker>
                        </Grid>

                        
                        <hr className={classes.hr}></hr>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    备注：<Input name="remark" value={this.state.remark} className={classes.inputs}
                                        onChange={this.handleInputChange} inputProps={{ 'aria-label': 'description' }} />
                                </Typography>
                            </Grid>
                        </Grid>
                        

                    

                        {/*
                        <Grid item>
                            <Typography className={this.state.showFace==1?classes.photosDIV:classes.hideMe} color="textSecondary" gutterBottom>
                                上传头像：<Upload {...uploadPhoto} showUploadList={{ "showDownloadIcon": false }} name="file">
                                    <Button style={{ display: "" }} className={classes.buttonsTwo}>
                                        <Icon type="upload" /> 点击上传
                                    </Button>
                                </Upload>
                                <ImageList rowHeight={180} className={classes.photoList}>
                                    {(this.state.photos || []).map((item) => (
                                        <ImageListItem key={item}>
                                            <img src={item} alt={item.title} />
                                        </ImageListItem>
                                    ))}
                                </ImageList>

                            </Typography>
                        </Grid>
                        */}


                        <Grid container justify="center" wrap="nowrap" spacing={2}>
                            <Button variant="contained" color="primary" className={classes.buttons} onClick={this.handleSubmit}>提&nbsp;&nbsp;&nbsp;交 </Button>
                        </Grid>
                    </Grid>
                </div>
            </Switch>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        classResult: state.introduceReducer.classResult,
        addPatientInfoResult: state.patientManageReducer.addPatientInfoResult,
        fileUrl: state.patientManageReducer.fileUrl,
        followupId: state.patientManageReducer.firstFollowUpId,
        createFirstFollowUpRet: state.patientManageReducer.createFirstFollowUpRet,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //查询随访基本信息
        queryFollowUpBasicInfo: (patientId) => {
            dispatch(queryFollowUpBasicInfo(patientId))
        },
        //提交学生信息
        addPatientInfo: patientInfo => {
            dispatch(addPatientInfo(patientInfo))
        },
        getClassList: () => {
            dispatch(getClassList());
        },
    }
}


//css region
const styles = theme => ({
    // 时间组件样式
    titleBoldBox: {
        marginLeft: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333',
        display: 'flex',
        // justifyContent: 'center'
    },
    pickersInputBorder: {
        border: 0,
    },
    timeContentBoxRight: {
        width: "60%"
    },
    textFieldContent: {
        width: '60vw'
    },
    /**************** 分割线 **************/
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        background: '#ffffff',
    },

    roots: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },

    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },

    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },

    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },

    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },

    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },

    file: {
        display: 'none',
        visibility: 'hidden',
    },

    buttonsTwo: {
        width: '25vw',
        height: '8vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '3vw',
        marginBottom: 'theme.spacing(2)',

        margin: theme.spacing(1),
        position: 'relative',
        border: '1px solid #00C9E4',
        borderRadius: '5px',
        padding: '4px 12px',
        overflow: 'hidden',
        color: '#fff',
        textDecoration: 'none',
        lineHeight: '8vw'
    },

    buttons: {
        width: '95vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginBottom: '10vw',
        fontSize: '4.5vw',
        marginTop: '5vw'
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },

    textField: {
        marginTop: theme.spacing(0),
        width: '90vw',
    },

    input: {
        margin: theme.spacing(0),
        width: '11vw'
    },

    inputs: {
        width: '90vw',
        marginBottom: theme.spacing(2),
    },

    rootDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
    },

    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },

    title1: {
        color: '#000000',
        fontSize: '4vw',
        fontWeight: '500',
    },

    span: {
        float: 'right'
    },

    titleBold: {
        marginLeft: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333'
    },

    titleButton: {
        marginLeft: theme.spacing(10),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },

    titleBoldDiv: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },

    titleTime: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333'
    },

    titleMain: {
        fontSize: '5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },

    titleBolds: {
        marginBottom: 0,
        fontSize: '4.2vw',
        lineHeight: '8vw',
        color: '#333333'
    },

    title: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '500',
        color: '#333333'
    },

    margin: {
        margin: theme.spacing(2),
    },

    spacemargin: {
        margin: theme.spacing(2),

    },

    titleBoldss: {
        marginTop: theme.spacing(2),
        fontSize: '4.2vw',
        color: '#333333'

    },

    doctor: {
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '700',
        color: '#333333'
    },

    inputName: {
        marginBottom: theme.spacing(1),
        fontWeight: '500',
        width: '67vw'
    },

    photosDIV: {
        marginBottom: theme.spacing(2),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },
    hideMe: {
        display: 'none'
    },
    dataDiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "90vw",
        height: "13.3vw",
        fontSize: "4.2vw",
    },

});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddPatient));
