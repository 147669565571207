/* 患者管理主页面 */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { makeStyles, withStyles, withTheme } from '@material-ui/core/styles';
import { Route, Switch } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DirectionsIcon from '@material-ui/icons/Directions';

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';

import wx from 'weixin-js-sdk';

import returnImg from '../assets/images/returnImg.png'
import Toast from "antd-mobile/lib/toast";

import Api from '../redux/api/api';
import axios from "axios";


//医生端主页面
class WorkView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idNumber: ''
        };
        this.handleReturnClick = this.handleReturnClick.bind(this);
        this.screenByIdNumber = this.screenByIdNumber.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goScan = this.goScan.bind(this);
    }

    componentDidMount() {
        this.setState({
            curNav: localStorage.getItem("curRole") == null ? 0 : localStorage.getItem("curRole")
        })

        //init wechat config
        let wxParams = null;//localStorage.getItem("wxParams")
        if (wxParams == null || wxParams.expireTime < new Date().getTime() / 1000) {//has no wx config
            const url = Api.getRootUrl() + '/api/wx/getWxConfig'
            let that = this;
            let params = {
                userId: 111,//localStorage.getItem("userId"),
                token: 'fsafowerpokpo',//localStorage.getItem("token"),
                url: window.location.href
            }
            axios.post(url, params).then(function (rsp) {
                console.log(rsp.data.code)
                if (rsp.data.code == 200) {
                    //Toast.show("接口返回成功！")
                    let wxParams = rsp.data.data.wxParams
                    if (wxParams != null) {
                        //Toast.show("配置微信参数！")
                        wx.config({
                            appId: wxParams.appId,
                            timestamp: wxParams.timestamp,
                            nonceStr: wxParams.nonceStr,
                            signature: wxParams.signature,
                            jsApiList: ['scanQRCode']
                        })
                        localStorage.setItem("wxParams", wxParams)
                        //Toast.show("获取配置成功！")
                    }

                } else {
                    Toast.info("获取配置失败!" + rsp.data.msg)
                }
            }).catch(function (err) {
                console.log(err)
                Toast.info("网络问题，请稍后再试！")
            })
        } else {//has wx config
            //Toast.show("使用本地数据！")
            wx.config({
                appId: wxParams.appId,
                timestamp: wxParams.timestamp,
                nonceStr: wxParams.nonceStr,
                signature: wxParams.signature,
                jsApiList: ['scanQRCode']
            })
        }
        //auto start scan
        let autoScan = localStorage.getItem("autoScan")
        if (autoScan) {
            let that = this;
            wx.ready(function () {
                that.goScan()
                //localStorage.removeItem("autoScan")
            })
        }


        wx.error(function (res) {
            Toast.show(JSON.stringify(res))
        })

    }

    componentWillUnmount() {
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.serverInfo !== this.props.serverInfo) {
        }
    }


    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });

    }

    handleReturnClick() {
        this.props.history.goBack();
    }

    goScan() {
        console.log("scan...")
        let that = this;
        wx.scanQRCode({
            needResult: 1, // 扫描结果需要回传给页面
            scanType: ['qrCode'], // 扫描类型：二维码、条形码
            success: function (res) {
                var result = res.resultStr; // 扫描结果
                // 在这里处理扫描结果的逻辑
                console.log('扫描结果：' + result);
                //Toast.show('扫描结果：' + result)
                //$("#cnt").html(result);
                //window.location.href = "http://wxmp.yidudao.cn/land.html";
                //alert(result);
                //window.location.href = "" + result;
                that.goScreen(result)
            },
            fail: function (err) {
                // 扫描失败的处理逻辑
                //Toast.show('扫描失败:'+err);
                Toast.show(JSON.stringify(err))
            }
        });
        localStorage.removeItem("autoScan");
    }

    goScreen(userCode) {
        const url = Api.getRootUrl() + '/api/wx/scanUserQr';
        let that = this;
        let params = {
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
            code: ''+userCode,
        }
        axios.post(url, params).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                const path = `/doctorCheck`;
                const params = {
                    patientId: rsp.data.data.patientId
                }
                that.props.history.push(path,params)


            } else {
                Toast.info("获取信息失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("网络问题，请稍后再试！")
        })
    }

    screenByIdNumber() {
        console.log(this.state.idNumber)
        const url = Api.getRootUrl() + '/api/wx/scanUserByIdNumber';
        let that = this;
        let params = {
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
            idNumber: this.state.idNumber,
        }
        axios.post(url, params).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                if(rsp.data.data.patientId){
                    const path = `/doctorCheck`;
                    const params = {
                        patientId: rsp.data.data.patientId
                    }
                    that.props.history.push(path,params)
                }else{
                    Toast.show("查询失败！"+rsp.data.msg);
                }


            } else {
                Toast.info("获取信息失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("网络问题，请稍后再试！")
        })
    }

    render() {
        //get react-material css styles
        const { classes } = this.props;

        return (
            <Switch>
                {/*<Route path={`${this.props.match.path}/chatWindow`} component={ChatWindow}/>*/}
                <Grid className={classes.root}>
                    <Grid container className={classes.Patient}>
                        <img src={returnImg} className={classes.jiantou} onClick={this.handleReturnClick} />
                        <Grid container justify="center" className={classes.headers}>
                            <h4 className={classes.headTitle}>工作台</h4>
                        </Grid>
                    </Grid>




                    <Grid className={classes.titleDiv}>
                        <Grid item xs={12} container direction="row" alignContent="center" justify="flex-start" GridWrap='nowrap'

                            className={classes.paperList}>
                            <Grid item xs={12} container direction="column" justify="center">
                                <Typography className={classes.title}>
                                    <b>方式一: 扫描二维码</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={classes.titleDiv}>
                        <Grid item xs={12} container direction="row" alignContent="center" justify="flex-start" GridWrap='nowrap'

                            className={classes.paperList}>
                            <Grid item xs={12} container justifyContent="center" >
                                <Link onClick={this.goScan}>点击扫码 {">>"}</Link>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className={classes.titleDiv}>
                        <Grid item xs={12} container direction="row" alignContent="center" justify="flex-start" GridWrap='nowrap'

                            className={classes.paperList}>
                            <Grid item xs={12} container direction="column" justify="center">
                                <Typography className={classes.title}>
                                    <b>方式二: 输入身份证号</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={classes.titleDiv}>
                        <Grid item xs={12} container direction="row" alignContent="center" justify="flex-start" GridWrap='nowrap'

                            className={classes.paperList}>
                            <Grid item xs={12} container justifyContent="center" >
                                <Paper component="form" className={classes.rootInput}>
                                    <InputBase
                                        name="idNumber"
                                        onChange={this.handleInputChange}
                                        className={classes.input}
                                        placeholder="请输入身份证号"
                                        inputProps={{ 'aria-label': 'search google maps' }}
                                        value={this.state.idNumber}
                                    />
                                    <Divider className={classes.divider} orientation="vertical" />
                                    <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick={this.screenByIdNumber}>
                                        <DirectionsIcon />
                                    </IconButton>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>



                </Grid>

            </Switch>
        );
    }

}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (userInfo) => {
            // dispatch(loginRequest(userInfo))
        },
    }
}


//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        height: '100vh',
        backgroundColor: '#ffffff'
        // padding: theme.spacing(0, 3),
    },
    // spacemargin: {
    //   margin: theme.spacing(1),

    // },
    Patient: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '14vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    jiantou: {
        height: '4vw',
        marginLeft: theme.spacing(2),
    },

    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '85vw',
        height: '14vw',
    },
    headTitle: {
        color: '#FFFFFF'
    },
    titleDiv: {
        margin: '32px',
    },
    rootInput: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WorkView));