import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';


//医生端主页面
class WorkJumpView extends React.Component {
   constructor(props) {
      super(props);
      this.state = {

      };

   }

   h
   componentDidMount() {
      window.location.replace("/doctorWork")
   }

   componentWillUnmount() {

   }

   //fired when props changed
   componentWillReceiveProps(nextProps) {

   }
   

   render() {
      //get react-material css styles
      const {classes} = this.props;

      return (
         <div></div>
      );
   }

}


const mapStateToProps = (state) => {
   return {}
}

const mapDispatchToProps = (dispatch) => {
   return {}
}


//css region
const styles = theme => ({


});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WorkJumpView));
