import Api from '../api'

const loginApi = {
    // getRootUrl() {
    //      return 'http://localhost:8080';
    //     //return 'http://127.0.0.1:8080';
    // },

    init(appname, serveraddr, api_key, proto) {

    },

    //患者注册
    patientRegister(patientInfo){
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/user/regUser';


        const init = {
            method: 'POST',
            headers: headers,
            body : JSON.stringify(patientInfo)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

   //患者退出登录
   loginOutApi() {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')
      let url = Api.getRootUrl() + '/api/user/loginOut';

      const init = {
         method: 'POST',
         headers: initHeaders,
         body : JSON.stringify(localStorage.getItem("userId")*1)
      }

      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data;
         })
         .catch(e => {
            return {error: e};
         })
   },

    //患者登录
    patientLogin(patientInfo) {
        let initHeaders = new Headers()
        initHeaders.append('Accept', 'application/json, text/plain, */*')
        initHeaders.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/user/login';

        const init = {
            method: 'POST',
            headers: initHeaders,
            body : JSON.stringify(patientInfo)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    wxBaseLogin(baseInfo) {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')
      let url = Api.getRootUrl() + '/api/wx/getOpenId?code='+baseInfo.code+
         '&state='+ baseInfo.state;
      const init = {
         method: 'GET',
         headers: initHeaders
      }
      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data;
         })
         .catch(e => {
            return {error: e};
         })
   },


    //患者端我的资料
    getLoginView(patientId) {
        let token = localStorage.getItem("token")
        let initHeaders = new Headers()
        initHeaders.append('Accept', 'application/json, text/plain, */*')
        initHeaders.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/user/personInfo?type=3&id='+patientId+'&token='+token;

        const init = {
            method: 'GET',
            headers: initHeaders
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //患者端我的手机号码修改提交
    postMobileDiv(phoneNumber) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')

        //url代表的是从远程服务器请求数据
        let url = Api.getRootUrl() + '/api/user/modifyInfo';

        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(phoneNumber)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.code;
            })
            .catch(e => {
                return {error: e};
            })
    },


   //患者端获取OpenID
   getWxOpenIdOpstionApi(codeState) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      //url代表的是从远程服务器请求数据
      let url = Api.getRootUrl() + '/api/wx/getOpenId';

      const init = {
         method: 'POST',
         headers: headers,
         body: JSON.stringify(codeState)
      }

      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data.code;
         })
         .catch(e => {
            return {error: e};
         })
   },



   //患者端我的姓名修改提交
    postNameDiv(doctorName) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')

        //url代表的是从远程服务器请求数据
        let url = Api.getRootUrl() + '/api/user/modifyInfo';

        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(doctorName)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.code;
            })
            .catch(e => {
                return {error: e};
            })
    },

    modifyPatientPwd(patientInfo) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')

        //url代表的是从远程服务器请求数据
        let url = Api.getRootUrl() + '/api/user/modifyInfo';

        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(patientInfo)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.code;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //发送短信验证码
    sendValidCode(dataInfo) {
        let initHeaders = new Headers()
        initHeaders.append('Accept', 'application/json, text/plain, */*')
        initHeaders.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/user/sendValidCode';

        const init = {
        method: 'POST',
        headers: initHeaders,
        body : JSON.stringify(dataInfo)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(e => {
            return {error: e};
        })
    },


}

loginApi.init();

export default loginApi
