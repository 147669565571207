/* 患者管理主页面 */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { makeStyles, withStyles, withTheme } from '@material-ui/core/styles';
import { Route, Switch } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

import returnImg from '../assets/images/returnImg.png'
import Toast from "antd-mobile/lib/toast";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import Api from '../redux/api/api';
import axios from "axios";

// 引入antd 时间组件
import Moment from 'moment';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import '../assets/css/border.css';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';


const steps = ["问诊筛查", "注射化验", "结果上报"];

const BeautyFormControlLabel = withStyles({
    root: {
        margin: 0,
    },
    label: {
        fontSize: '1rem',
        marginLeft: '-1.8vw',
        color: 'black',
        fontWeight: 395,
    },
})(FormControlLabel);

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 14,
        height: 14,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 14,
            height: 14,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

const GreenCheckbox = withStyles({
    root: {
        color: "#00C9E4",
        "&$checked": {
            color: "#00C9E4"
        }
    },
    checked: {}
})(props => <Checkbox color="default" {...props} />);

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

//医生端主页面
class PatientDetailView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            schoolName: '清华附中',
            name: '果冻',
            className: '27级别高三2班',
        };
        this.handleReturnClick = this.handleReturnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.pickerPpdCheckTime = this.pickerPpdCheckTime.bind(this);
        this.pickerPpdCheckResultTime = this.pickerPpdCheckResultTime.bind(this);
        this.pickerEcCheckTime = this.pickerEcCheckTime.bind(this);
        this.pickerEcCheckResultTime = this.pickerEcCheckResultTime.bind(this);
        this.pickerIgraCheckTime = this.pickerIgraCheckTime.bind(this);
        this.pickerIgraCheckResultTime = this.pickerIgraCheckResultTime.bind(this);

        this.a11yProps = this.a11yProps.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    componentDidMount() {
        //const urlParams = new URLSearchParams(window.location.search);
        const patientId = this.props.history.location.state?.patientId;//urlParams.get('patientId');
        if (patientId == null) {
            Toast.show("加载出错，请返回重试！");
            return;
        }
        this.state.patientId = patientId;

        const url = Api.getRootUrl() + '/api/doctor/getScreenCheck'
        let that = this;
        let params = {
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
            patientId: patientId,
        }
        axios.post(url, params).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                that.setState({
                    activeStep: rsp.data.data?.activeStep,
                    name: rsp.data.data?.name,
                    schoolName: rsp.data.data?.schoolName,
                    className: rsp.data.data?.className,
                    //...rsp.data.data?.screening
                })
                if (rsp.data.data?.screening) {
                    //that.setState({...rsp.data.data?.screening})
                    //that.setState({hasTuberculosis:1});
                    let filteredData = {};
                    const scData = rsp.data.data?.screening
                    // 过滤掉值为 null 的属性
                    const skipKeys = ["userId"]
                    for (const key in scData) {
                        if(skipKeys.includes(key)) continue;
                        if (scData[key] !== null) {
                            filteredData[key] = scData[key];
                        }
                    }
                    that.setState(filteredData)
                }

            } else {
                Toast.info("加载个人信息失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("网络问题，请稍后再试！")
        })

    }

    componentWillUnmount() {
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.serverInfo !== this.props.serverInfo) {
        }
    }

    handleUpdate(){
        const url = Api.getRootUrl() + '/api/doctor/updateScreenCheck'
        let that = this;
        let params = {
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
            ...this.state
        }
        axios.post(url, params).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                Toast.show("提交成功！")
                that.handleReturnClick()

            } else {
                Toast.info("提交失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("网络问题，请稍后再试！")
        })
    }


    handleInputChange(event) {
        console.log(event)
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });

    }

    handleReturnClick() {
        this.props.history.goBack();
    }

    a11yProps(pageIndex) {
        return {
            id: `full-width-tab-${pageIndex}`,
            'aria-controls': `full-width-tabpanel-${pageIndex}`,
        };
    }
    handleChange(event, newValue) {
        this.setState({
            activeStep: newValue,
        })
    }

    pickerPpdCheckTime(date, dateString) {
        this.setState({
            ppdCheckTime: dateString.replace("年", "-").replace("月", "-").replace("日", "")
        })
    }
    pickerPpdCheckResultTime(date, dateString) {
        this.setState({
            ppdCheckResultTime: dateString.replace("年", "-").replace("月", "-").replace("日", "")
        })
    }
    pickerEcCheckTime(date, dateString) {
        this.setState({
            ecCheckTime: dateString.replace("年", "-").replace("月", "-").replace("日", "")
        })
    }
    pickerEcCheckResultTime(date, dateString) {
        this.setState({
            ecCheckResultTime: dateString.replace("年", "-").replace("月", "-").replace("日", "")
        })
    }
    pickerIgraCheckTime(date, dateString) {
        this.setState({
            igraCheckTime: dateString.replace("年", "-").replace("月", "-").replace("日", "")
        })
    }
    pickerIgraCheckResultTime(date, dateString) {
        this.setState({
            igraCheckResultTime: dateString.replace("年", "-").replace("月", "-").replace("日", "")
        })
    }


    render() {
        //get react-material css styles
        const { classes } = this.props;

        return (
            <Switch>
                {/*<Route path={`${this.props.match.path}/chatWindow`} component={ChatWindow}/>*/}
                <Grid className={classes.root}>

                    <AppBar position="static" color="default">
                        <Tabs
                            className={classes.navigation}
                            value={this.state.activeStep}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            variant="fullWidth"
                            aria-label="introduce tabs">
                            <Tab className={classes.manageMy} label="问诊筛查" {...this.a11yProps(0)} />
                            <Tab className={classes.default} label={<Badge badgeContent={this.props.noticeManageInfo?.followupNum} color="secondary">注射化验</Badge>} {...this.a11yProps(1)} />
                        </Tabs>
                    </AppBar>

                    <div className={classes.space}></div>


                    <Grid className={classes.roots}>
                        <Typography h5 noWrap className={classes.spacemargin}></Typography>
                        <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    学校：<span className={classes.title}>{this.state.schoolName}</span>
                                </Typography>
                            </Grid>

                        </Grid>

                        <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    姓名：<span className={classes.title}>{this.state.name}</span>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    班级：<span className={classes.title}>{this.state.className}</span>
                                </Typography>
                            </Grid>

                        </Grid>

                        <hr className={classes.hr}></hr>



                        {/*查验表*/}
                        <div className={this.state.activeStep == 0 ? classes.ask : classes.hideMe}>
                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 1、既往结合病史（含肺外结核）：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasTuberculosis" value={this.state.hasTuberculosis}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasTuberculosis == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasTuberculosis == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>


                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 2、家庭成员或经常接触的亲戚朋友中、原同班师生或同宿舍学生有无结核病患者：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasContactTuberculosis" value={this.state.hasContactTuberculosis}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasContactTuberculosis == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasContactTuberculosis == 0} />
                                </RadioGroup>
                            </Grid>

                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 3、连续咳嗽或咳痰超过2周：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasCoughWeek" value={this.state.hasCoughWeek}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasCoughWeek == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasCoughWeek == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 4、咯血或血痰：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasCoughBlood" value={this.state.hasCoughBlood}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasCoughBlood == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasCoughBlood == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 5、胸痛、胸闷及气短：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasChestPain" value={this.state.hasChestPain}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasChestPain == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasChestPain == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 6、反复低热：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasRepeatFever" value={this.state.hasRepeatFever}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasRepeatFever == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasRepeatFever == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>


                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 7、盗汗：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasSweat" value={this.state.hasSweat}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasSweat == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasSweat == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 8、消瘦或体重下降：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasLostWeight" value={this.state.hasLostWeight}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasLostWeight == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasLostWeight == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 9、乏力、食欲减退：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasWeak" value={this.state.hasWeak}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasWeak == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasWeak == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 10、其他肺结核可疑症状：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasIssueOthers" value={this.state.hasIssueOthers}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasIssueOthers == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasIssueOthers == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>
                            <Grid container justifyContent="flex-start" >
                                <Grid item xs={12}>
                                    <Typography className={classes.title} style={{ textAlign: 'left' }} gutterBottom>如有,请输入其他症状详细内容：</Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <Grid item xs={12}>
                                    <Input name="issueOthers" value={this.state.issueOthers} className={classes.inputFull}
                                        onChange={this.handleInputChange} />
                                </Grid>
                            </Grid>

                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 11、急性传染病（如麻疹、百日咳、流行性感冒、肺炎等）：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasEpidemic" value={this.state.hasEpidemic}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasEpidemic == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasEpidemic == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 12、急性眼结膜炎：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasConjunctivitis" value={this.state.hasConjunctivitis}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasConjunctivitis == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasConjunctivitis == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>


                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 13、急性中耳炎：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasTympanitis" value={this.state.hasTympanitis}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasTympanitis == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasTympanitis == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 14、全身性皮肤病：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasSkinDisease" value={this.state.hasSkinDisease}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasSkinDisease == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasSkinDisease == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 15、过敏体质：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasAllergic" value={this.state.hasAllergic}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasAllergic == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasAllergic == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 16、判定不适合进行皮肤试验的其他情况：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row defaultValue="0" name="hasOtherTaboo" value={this.state.hasOtherTaboo}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasOtherTaboo == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasOtherTaboo == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>
                            <Grid container justifyContent="flex-start" >
                                <Grid item xs={12}>
                                    <Typography className={classes.title} style={{ textAlign: 'left' }} gutterBottom>如有,请输入其他情况详细内容：</Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <Grid item xs={12}>
                                    <Input name="otherTaboo" value={this.state.otherTaboo} className={classes.inputFull}
                                        onChange={this.handleInputChange} />
                                </Grid>
                            </Grid>

                            <div className={classes.space}> </div>
                            <div className={classes.space}> </div>
                        </div>

                        {/*PPD*/}
                        <div className={this.state.activeStep > 0 ? '' : classes.hideMe}>
                            <Grid container justify="space-between" wrap="nowrap" spacing={2} >
                                <Grid item>
                                    <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                        <Grid>PPD检查时间：</Grid>
                                        <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " placeholder="请选择日期"
                                            allowClear={false} className="date-picker-border" defaultValue={this.state.ppdCheckTime == null ? null : Moment(this.state.ppdCheckTime)} value={this.state.ppdCheckTime == null ? null : Moment(this.state.ppdCheckTime)}
                                            onChange={this.pickerPpdCheckTime} />
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container justify="space-between" wrap="nowrap" spacing={2} >
                                <Grid item>
                                    <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                        <Grid>PPD结果时间：</Grid>
                                        <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " placeholder="请选择日期"
                                            allowClear={false} className="date-picker-border" defaultValue={this.state.ppdCheckResultTime == null ? null : Moment(this.state.ppdCheckResultTime)} value={this.state.ppdCheckResultTime == null ? null : Moment(this.state.ppdCheckResultTime)}
                                            onChange={this.pickerPpdCheckResultTime} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        PPD反应：
                                    </Typography>
                                </Grid>
                                <RadioGroup row name="ppdReact" value={this.state.ppdReact} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.ppdReact == 1} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.ppdReact == 0} />
                                </RadioGroup>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                        <span className={classes.titleBold}>硬结横径（mm）：</span>
                                        <Input name="ppdHardenDiameterX" value={this.state.ppdHardenDiameterX} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                        <span className={classes.titleBold}>硬结纵径（mm）：</span>
                                        <Input name="ppdHardenDiameterY" value={this.state.ppdHardenDiameterY} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                        <span className={classes.titleBold}>硬结平均直径：</span>
                                        <Input name="ppdHardenDiameter" value={this.state.ppdHardenDiameter} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        是否水泡：
                                    </Typography>
                                </Grid>
                                <RadioGroup row name="ppdBlister" value={this.state.ppdBlister} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ppdBlister == 1} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ppdBlister == 0} />
                                </RadioGroup>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        是否双圈：
                                    </Typography>
                                </Grid>
                                <RadioGroup row name="ppdDoubleLoop" value={this.state.ppdDoubleLoop} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ppdDoubleLoop == 1} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ppdDoubleLoop == 0} />
                                </RadioGroup>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        是否坏死：
                                    </Typography>
                                </Grid>
                                <RadioGroup row name="ppdNecrosis" value={this.state.ppdNecrosis} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ppdNecrosis == 1} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ppdNecrosis == 0} />
                                </RadioGroup>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        是否淋巴管炎：
                                    </Typography>
                                </Grid>
                                <RadioGroup row name="ppdLymphangitis" value={this.state.ppdLymphangitis} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ppdLymphangitis == 1} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ppdLymphangitis == 0} />
                                </RadioGroup>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        PPD结果判定：
                                    </Typography>
                                </Grid>
                                <RadioGroup row name="ppdResult" value={this.state.ppdResult} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="阴性" checked={this.state.ppdResult == 1} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="一般阳性" checked={this.state.ppdResult == 2} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio />} label="中阳" checked={this.state.ppdResult == 3} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio />} label="强阳" checked={this.state.ppdResult == 4} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="未检查" checked={this.state.ppdResult == 0} />
                                </RadioGroup>
                            </Grid>


                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                        <span className={classes.titleBold}>备注：</span>
                                        <Input name="ppdRemark" value={this.state.ppdRemark} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                    </Typography>
                                </Grid>
                            </Grid>


                            {/*EC*/}
                            <hr className={classes.hr}></hr>
                            <Grid container justify="space-between" wrap="nowrap" spacing={2} >
                                <Grid item>
                                    <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                        <Grid>EC检查时间：</Grid>
                                        <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " placeholder="请选择日期"
                                            allowClear={false} className="date-picker-border" defaultValue={this.state.ecCheckTime == null ? null : Moment(this.state.ecCheckTime)} value={this.state.ecCheckTime == null ? null : Moment(this.state.ecCheckTime)}
                                            onChange={this.pickerEcCheckTime} />
                                    </Typography>
                                </Grid>
                            </Grid>


                            <Grid container justify="space-between" wrap="nowrap" spacing={2} >
                                <Grid item>
                                    <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                        <Grid>EC结果时间：</Grid>
                                        <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " placeholder="请选择日期"
                                            allowClear={false} className="date-picker-border" defaultValue={this.state.ecCheckResultTime == null ? null : Moment(this.state.ecCheckResultTime)} value={this.state.ecCheckResultTime == null ? null : Moment(this.state.ecCheckResultTime)}
                                            onChange={this.pickerEcCheckResultTime} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        EC反应：
                                    </Typography>
                                </Grid>
                                <RadioGroup row name="ecReact" value={this.state.ecReact} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.ecReact == 1} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.ecReact == 0} />
                                </RadioGroup>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                        <span className={classes.titleBold}>EC硬结或红晕较大值的平均直径(mm)：</span>
                                        <Input name="ecHardenDiameter" value={this.state.ppdHardenDiameterX} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        是否水泡：
                                    </Typography>
                                </Grid>
                                <RadioGroup row name="ecBlister" value={this.state.ecBlister} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ecBlister == 1} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ecBlister == 0} />
                                </RadioGroup>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        是否双圈：
                                    </Typography>
                                </Grid>
                                <RadioGroup row name="ecDoubleLoop" value={this.state.ecDoubleLoop} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ecDoubleLoop == 1} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ecDoubleLoop == 0} />
                                </RadioGroup>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        是否坏死：
                                    </Typography>
                                </Grid>
                                <RadioGroup row name="ecNecrosis" value={this.state.ecNecrosis} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ecNecrosis == 1} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ecNecrosis == 0} />
                                </RadioGroup>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        是否淋巴管炎：
                                    </Typography>
                                </Grid>
                                <RadioGroup row name="ecLymphangitis" value={this.state.ecLymphangitis} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ecLymphangitis == 1} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ecLymphangitis == 0} />
                                </RadioGroup>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        EC结果判定：
                                    </Typography>
                                </Grid>
                                <RadioGroup row name="ecResult" value={this.state.ecResult} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="阳性" checked={this.state.ecResult == 1} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="阴性" checked={this.state.ecResult == 2} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="未检查" checked={this.state.ecResult == 0} />
                                </RadioGroup>
                            </Grid>



                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                        <span className={classes.titleBold}>备注：</span>
                                        <Input name="ecRemark" value={this.state.ecRemark} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                    </Typography>
                                </Grid>
                            </Grid>

                            {/*IGRA*/}
                            <hr className={classes.hr}></hr>
                            <Grid container justify="space-between" wrap="nowrap" spacing={2} >
                                <Grid item>
                                    <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                        <Grid>IGRA检查时间：</Grid>
                                        <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " placeholder="请选择日期"
                                            allowClear={false} className="date-picker-border" defaultValue={this.state.igraCheckTime == null ? null : Moment(this.state.igraCheckTime)} value={this.state.igraCheckTime == null ? null : Moment(this.state.igraCheckTime)}
                                            onChange={this.pickerIgraCheckTime} />
                                    </Typography>
                                </Grid>
                            </Grid>


                            <Grid container justify="space-between" wrap="nowrap" spacing={2} >
                                <Grid item>
                                    <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                        <Grid>IGRA结果时间：</Grid>
                                        <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " placeholder="请选择日期"
                                            allowClear={false} className="date-picker-border" defaultValue={this.state.igraCheckResultTime == null ? null : Moment(this.state.igraCheckResultTime)} value={this.state.igraCheckResultTime == null ? null : Moment(this.state.igraCheckResultTime)}
                                            onChange={this.pickerIgraCheckResultTime} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        IGRA结果判定：
                                    </Typography>
                                </Grid>
                                <RadioGroup row name="igraResult" value={this.state.igraResult} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="阳性" checked={this.state.igraResult == 1} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="阴性" checked={this.state.igraResult == 2} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio />} label="不确定" checked={this.state.igraResult == 3} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="未检查" checked={this.state.ecResult == 0} />
                                </RadioGroup>
                            </Grid>


                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                        <span className={classes.titleBold}>备注：</span>
                                        <Input name="igraRemark" value={this.state.igraRemark} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container justify="center" wrap="nowrap" spacing={2}>
                                <Grid item xs={12} className={classes.buttonDiv}>
                                    <Button variant="contained" color="secondary" className={classes.buttonUpdate}
                                        onClick={this.handleUpdate}>
                                        更新
                                    </Button>
                                    <Button variant="contained" color="secondary" className={classes.buttonRemove} color="primary"
                                        onClick={this.handleReturnClick}>
                                        返回
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>


                    </Grid>

                </Grid>

            </Switch >
        );
    }

}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (userInfo) => {
            // dispatch(loginRequest(userInfo))
        },
    }
}


//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        //overflow: 'hidden',
        backgroundColor: '#ffffff',
        minHeight: '100vh',
        // padding: theme.spacing(0, 3),
    },
    // spacemargin: {
    //   margin: theme.spacing(1),

    // },
    navigation: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '12vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',

    },

    notification: {
        fontSize: '4vw',
        color: 'rgba(255,255,255,1)',
    },
    manageMy: {
        fontSize: '4vw',
        color: 'rgba(255,255,255,1)',
    },
    default: {
        color: 'rgba(255,255,255,1)',
        fontSize: '4vw'
    },
    roots: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    Patient: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '14vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    jiantou: {
        height: '4vw',
        marginLeft: theme.spacing(2),
    },

    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '85vw',
        height: '14vw',
    },
    headTitle: {
        color: '#FFFFFF'
    },
    titleDiv: {
        margin: '32px',
    },
    rootInput: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    dataDiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "90vw",
        height: "13.3vw",
        fontSize: "4.2vw",
    },
    space: {
        height: '5px',
        margin: '5px',
    },
    hideMe: {
        display: 'none'
    },
    ask: {
        padding: '1.5rem',
    },
    titleBold: {
        marginLeft: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333'
    },
    title: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '500',
        color: '#333333'
    },
    // 时间组件样式
    titleBoldBox: {
        marginLeft: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333',
        display: 'flex',
        // justifyContent: 'center'
    },
    pickersInputBorder: {
        border: 0,
    },
    timeContentBoxRight: {
        width: "60%"
    },
    textFieldContent: {
        width: '60vw'
    },
    buttonDiv: {
        height: '16vw',
        justifyContent: 'space-around',
        alignItems: 'center',
        display: 'flex',
    },
    buttons: {
        margin: theme.spacing(2),
        fontSize: '4.8vW',
        width: '91vw',
        height: '12vw',
        background: 'linear-gradient(to right,#FF7F50, 50%, #FF8C00)',
    },
    buttonUpdate: {
        width: '25vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
    },
    buttonRemove: {
        width: '25vw',
        //background: 'linear-gradient(to right,#32CD32, 50%, #00C957)',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
    },
});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PatientDetailView));