import {
    DOCTOR_LOGIN_ACTION,
    GET_MYINFO,
    GET_MYINTRODUCELIST,
    GET_MYTRACKLIST,
    GET_MYTRACKNOTICELIST,
    GET_MYVIEW,
    GET_NOTICELIST,
    NOTICE_READED,
    GET_REGIONLIST,
    GET_CLASSLIST,
    GET_REASONLIST,
    INTRODUCE_DETAIL,
    INTRODUCE_NOTICE_DAYS7,
    INTRODUCE_NOTICE_DETAIL,
    INTRODUCE_NOTIFY_TREATED,
    POST_APPOINTMENTINFO,
    POST_APPOINTMENTRESERVEINFO,
    POST_INTRODUCENEWINFO,
    POST_NAMEDIV,
    POST_PHONENUMBER,
    POST_SENDINTRODUCEINFO,
    TRACK_APPO_INTMENT,
    TRACK_FAILURE,
    GET_PATIENT_BASIC_NEWS_LIST,
    GET_PATIENT_MESSAGE_NEWS_LIST,
    GET_PATIENT_NEWS_DETAIL,
    GET_DOCTOR_BASIC_NEWS_LIST,
    GET_DOCTOR_MESSAGE_NEWS_LIST,
    GET_DOCTOR_NEWS_DETAIL,
    RESET_INTRODUCE_NEWREPLY,
    GET_DOCTOR_TEACH_MESSAGE
} from "./constants";


//医生登录事件绑定
export function doctorLogin(loginInfo) {
  return {
    type: DOCTOR_LOGIN_ACTION,
      loginInfo
  };
}

//患者推介-我的推介
export function getMyIntroduceList(doctorId) {
    return {
        type: GET_MYINTRODUCELIST,
        doctorId
    };
}

//我的推介-查看详情
export function introduceDetail(introId) {
  return {
    type: INTRODUCE_DETAIL,
      introId
  };
}

//患者推介-新建推介
export function submitIntroduceNewInfo(introNewInfo) {
    return {
        type: POST_INTRODUCENEWINFO,
        introNewInfo
    };
}

  //患者推介 重置推介返回状态码
  export function resetIntroduceNewReply(code){
    return {
      type: RESET_INTRODUCE_NEWREPLY,
      code
  };

  }

//患者推介-通知
export function getNoticeList(noticeType) {
  return {
    type: GET_NOTICELIST,
    noticeType
  };
}

export function noticeReaded(notice){
    return {
        type : NOTICE_READED,
        notice
    }
}

//患者推介-通知-查看详情页(3天)
export function noticeDetail(noticeId) {
    return {
      type:INTRODUCE_NOTICE_DETAIL,
        noticeId
    };
}

//患者推介-通知-查看详情页(7天)
export function introduceNotice7Days(noticeId) {
    return {
      type : INTRODUCE_NOTICE_DAYS7,
        noticeId
    }
}

//患者推介-通知-查看详情页(已就诊)
export function getIntroduceNotifyTreated(noticeId) {
    return {
        type : INTRODUCE_NOTIFY_TREATED,
        noticeId
    }
}


//患者追踪 - 我的追踪
export function getMyTrackList(doctorId) {
  return {
    type: GET_MYTRACKLIST,
      doctorId
  };
}

//患者追踪 -通知详情页
export function getTrackAppointment(noticeId) {
    return {
        type : TRACK_APPO_INTMENT,
        noticeId
    }
}

//患者追踪 -通知 -列表页
//获取患者追踪通知详情页追踪失败
export function postTrackfailure(failureReason) {
    return {
        type : TRACK_FAILURE,
        failureReason
    }
}

export function getMyTrackNoticeList(noticeType) {
  return {
    type: GET_MYTRACKNOTICELIST,
    noticeType
  };
}


//患者追踪-预约就诊
export function submitAppointmentInfo(appointInfo) {
  return {
    type: POST_APPOINTMENTINFO,
    appointInfo
  };
}


export function submitAppointmentReserveInfo(appointReserveInfo) {
  return {
    type: POST_APPOINTMENTRESERVEINFO,
    appointReserveInfo
  };
}

export function submitSendIntroduceInfo(sendIntroduceInfo) {
  return {
    type: POST_SENDINTRODUCEINFO,
    sendIntroduceInfo
  };
}

export function getMyInfo(myProfile) {
  return {
    type: GET_MYINFO,
    myProfile
  };
}

// 获取住址信息
export function getRegionList() {
  return {
    type: GET_REGIONLIST
  };
}

// 获取推介原因
export function getReasonList() {
  return {
    type: GET_REASONLIST
  };
}


//医生端我的资料
export function getMyView(userId) {
    return {
        type: GET_MYVIEW,
        userId
    };
}


//医生端手机号修改提交
export function postMobileDiv(phoneNumber) {
    return {
        type:POST_PHONENUMBER,
        phoneNumber
    }
}

//医生端姓名修改提交
export function postNameDiv(doctorName) {
    return {
        type:POST_NAMEDIV,
        doctorName
    }
}

//患者端基本新闻列表
export function getPatientBasicNewsList() {
  return {
      type: GET_PATIENT_BASIC_NEWS_LIST
  };
}
//患者端消息新闻列表
export function getPatientMessageNewsList() {
  return {
      type: GET_PATIENT_MESSAGE_NEWS_LIST
  };
}
//患者端新闻详情
export function getPatientNewsDetail(newsId) {
  return {
      type: GET_PATIENT_NEWS_DETAIL,
      newsId
  };
}
//医生端端基本新闻列表
export function getDoctorBasicNewsList() {
  return {
      type: GET_DOCTOR_BASIC_NEWS_LIST
  };
}
//医生端消息新闻列表
export function getDoctorMessageNewsList() {
  return {
      type: GET_DOCTOR_MESSAGE_NEWS_LIST
  };
}
//医生端新闻详情
export function getDoctorNewsDetail(newsId) {
  return {
      type: GET_DOCTOR_NEWS_DETAIL,
      newsId
  };
}
// 医生端教育宣讲
export function getDoctorTeachMessage() {
   return {
      type: GET_DOCTOR_TEACH_MESSAGE,
   };
}

// 获取住址信息
export function getClassList() {
  return {
    type: GET_CLASSLIST
  };
}