import * as ActionTypes from "../actions/constants";

// The initial application state
let initialState = {
    introduceNewReply: 404,
    introduceList: {},

    regionObject: {},
    introduceDetail: {},
    introduceNoticeDetail:{},
    introduceNoticeDays7:{},
    introduceNotifyTreated:{},
    noticeList: [],
    isRead:500
};

// Takes care of changing the application state
function introduceReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.MYINTRODUCELIST_ARRIVED: //拿到我的推介列表
            return {...state, introduceList: action.result};
        case ActionTypes.NOTICELIST_ARRIVED: //拿到我的推介列表
            return {...state, noticeList: action.result};
        case ActionTypes.INTRODUCE_DETAIL_SUCCEED: //我的推介-查看详情
            return {...state, introduceDetail: action.result};
        case ActionTypes.NOTICE_READED_SUCCEED:
            return {...state,isRead: action.result}
        case ActionTypes.INTRODUCE_NOTICE_DETAIL_SUCCEED: //患者推介-通知-查看详情 结果获取成功
            return {...state,introduceNoticeDetail:action.result};
        case ActionTypes.INTRODUCE_NOTICE_DAYS7_SUCCEED:
            return {...state,introduceNoticeDays7:action.result};
        case ActionTypes.INTRODUCE_NOTIFY_TREATED_SUCCEED://患者推介-通知-已就诊
            return {...state,introduceNotifyTreated:action.result};
        case ActionTypes.SUBMITINTRODUCENEWINFO_ARRIVED: //拿到提交新建推介的结果
            return {...state, introduceNewReply: action.result};
        case ActionTypes.RESET_INTRODUCE_NEWREPLY: //重置新建推介返回码
            return {...state,introduceNewReply:action.result};
        case ActionTypes.SUBMITAPPOINTMENTINFO_ARRIVED: //拿到提交新建推介的结果
            return {...state, submitAppointmentReply: action.result};
        case ActionTypes.SET_REGIONLIST: //获取住址信息
        //先进行拆分,分成两个state
            return {...state, regionObject: action.regionObject};
        case ActionTypes.SET_CLASSLIST: //获取班级信息
            return {...state, classResult: action.result};
        case ActionTypes.SET_REASONLIST: //获取推介原因信息
            return {...state, reasonObject: action.reasonObject};
        default:
            return state;
    }

}

function divideRegionList(regionList){
    //直接return一个json,包括两套数据
    regionList.forEach(function(val1,index1){
        val1.children.forEach(function(val2,index2){

            val2.children.forEach(function(val3,index3){
                val3.children=null;//截断,不知道是否有必要
            })

        })
    })

}


export default introduceReducer;
