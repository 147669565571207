import {call, put, takeLatest} from "redux-saga/effects";
import * as ActionType from "../actions/patient_manage_action";

import api from "../api/patient_manage";
import {SERVICE_SIGN_AGREEMENT_COMMIT} from "../actions/patient_manage_action";
import {YAO_HE_UNTYING} from "../actions/patient_manage_action";

//患者管理-我的患者 -患者情况
export function* getMyPatients(action) {
    try {
        const result = yield call(api.queryMyPatients,action.loginInfo);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "MY_PATIENTS_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者管理-我的患者 - 服药记录
export function* queryMedicationRecord(action) {
    try {
        const result = yield call(api.queryMedicationRecordList,action.loginInfo);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "MEDICATION_RECORD_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者管理 - 我的患者 - 患者详情
export function* queryPatientDetail(action) {
    try {
        const result = yield call(api.queryPatientDetail,action.patientId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "PATIENT_DETAIL_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者管理 - 我的患者 - 绑定
export function* queryBindUnbindCommon(action) {
   try {
      const result = yield call(api.queryBindUnbindApi,action.boxBangding);
      if (result.error !== undefined) {
         //请求出错了
         yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
      } else {
         yield put({type: "BANG_DING_JIE_BANG_SUCCEED", result});
      }
   }catch (e) {
      yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
   }
}

//患者管理 - 我的患者 - 解绑
export function* queryUntyingCommon(action) {
   try {
      const result = yield call(api.queryUntyingApi,action.untyingParams);
      if (result.error !== undefined) {
         //请求出错了
         yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
      } else {
         yield put({type: "YAO_HE_UNTYING_SUCCEED", result});
      }
   }catch (e) {
      yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
   }
}

//患者管理 - 我的患者 - 药盒列表
export function* queryMedicalKitCommon(action) {
   try {
      const result = yield call(api.queryMedicalKitdApi,action);
      if (result.error !== undefined) {
         //请求出错了
         yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
      } else {
         yield put({type: "YAO_HE_LIST_SUCCEED", result});
      }
   }catch (e) {
      yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
   }
}

//患者管理 - 我的患者 - 服药情况 - 服药情况详情
export function* queryPatientTakeMedicineRecord(action) {
    try {
        const result = yield call(api.queryPatientTakeMedicineRecord,action.patientId);

        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "PATIENT_TAKE_MEDICINE_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者管理 - 我的患者 - 患者详情 - 查痰记录
export function* queryCheckSputumRecord(action) {
    try {
        const result = yield call(api.queryCheckSputumRecord,action.patientId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "QUERY_CHECK_SPUTUM_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者管理-通知列表
export function* getManageNotice(action) {
    try {
        const result = yield call(api.getManageNotice,action.userId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "POST_MANAGENOTICE_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//确认管理
export function* sureManage(action) {
    try {
        const result = yield call(api.sureManage,action.obj);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "SURE_MANAGE_OK", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者管理-待随访
export function* PostManageWait(action) {
    try {
        const result = yield call(api.PostManageWait,action.userInfo);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "POST_MANAGEWAIT_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者管理-待随访 - 日常随访
export function* saveVisitInfo(action) {
    try {
        const result = yield call(api.saveFollowUpVisit,action.visitorInfo);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "POST_FOLLOWPAPER_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者管理-待随访 - 添加首次随访记录
export function* postFirstFollowUp(action) {
    try {
        const result = yield call(api.postFirstFollowUp,action.visitorNewInfo);

        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "POST_FOLLOWPAPERNEWINFO_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者管理-待随访 - 首次随访枚举值
export function* followUpVisitPaperNewInfo2(action) {
    try {
        const result = yield call(api.followUpVisitPaperNewInfo2,action.doctorId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "POST_FOLLOWPAPERNEWINFOTWO_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者管理 -通知列表-停止治疗管理通知单
export function* stopTreatBill(action) {
    try {
        const result = yield call(api.queryStopTreatBill,action.patientInfo);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "POST_FOLLOWUP_4_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* finishTreat(action){
    try {
        const result = yield call(api.finishTreat,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "FINISH_TREAT_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}


//患者管理 -患者管理 -通知列表-新患者确认管理通知单
export function* postFollowUpVisitPaper1(action) {
    try {
        const result = yield call(api.postFollowUpVisitPaper1,action.patientId);

        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "POST_FOLLOWUP_1_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}



//患者管理 -通知列表-患者治疗管理反馈单
export function* postFollowUpVisitPaper5(action) {
    try {
        const result = yield call(api.postFollowUpVisitPaper5,action.params);

        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "POST_FOLLOWUP_5_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者管理 -通知列表-患者治疗管理反馈单（提交）
export function* postfeedbackSubmission(action) {
    try {
        const result = yield call(api.postfeedbackSubmission,action.feedBack);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "FEEDBACKS_SUBMISSION_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者管理 - 患者主页 - 点击服药
export function* postTakeMedicineEvent(action) {
   try {
      const result = yield call(api.postTakeMedicineEvent,action);
      if (result.error !== undefined) {
         yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
      } else {
         yield put({type: "POST_TAKE_MEDICINE_EVENT_SUCCEED", result});
      }
   }catch (e) {
      yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
   }
}

//患者管理 - 患者主页 - 查询服药状态
export function* getTakeMedicineStatus(action) {
   try {
      const result = yield call(api.getTakeMedicineStatus);
      if (result.error !== undefined) {
         yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
      } else {
         yield put({type: "GET_TAKE_MEDICINE_STATUS_SUCCEED", result });
      }
   }catch (e) {
      yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
   }
}


//日常随访
export function* followUpVisitPaper(action){
    try {
        const result = yield call(api.followUpVisitPaper,action.doctorId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "POST_FOLLOWPAPER_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//首次随访基本信息
export function* queryFollowUpBasicInfo(action) {
    try {
        const result = yield call(api.queryFollowUpBasicInfo,action.patientId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "QUERY_FIRST_FOLLOWUP_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//上传文件
export function* uploadFile(action) {
    try {
        const result = yield call(api.uploadFile,action.file);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "UPLOAD_FILE_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//随访历史
export function* followUpHistory(action) {
    try {
        const result = yield call(api.queryFollowUpHistory,action.patientId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: ActionType.FOLLOW_UP_HISTORY_SUCCEED, result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//随访结案
export function* stopFollowUp(action) {
    try {
        const result = yield call(api.stopFollowUp,action.patientId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: ActionType.STOP_FOLLOW_UP_SUCCEED, result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者管理通知已读
export function* read(action) {
    try {
        yield call(api.read,action.readInfo);
        yield put({type: "UPLOAD_FILE_SUCCEED"});
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* confirmStopTreat(action){
    try {
        yield call(api.confirmStopTreat,action.id);
        yield put({type: "CONFIRMSTOPTREAT_SUCCEED"});
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//签约服务协议
export function* queryServiceProtocolInfo(action){
    try {
        const result = yield call(api.queryServiceProtocol,action.params);

        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: ActionType.QUERY_SERVICE_PROTOCOL_INFO_SUCCEED, result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}


//签约服务协议提交
export function* commitServiceSignAgreement(action){
    try {
        const result = yield call(api.commitServiceSignAgreement,action.serviceSign);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: ActionType.SERVICE_SIGN_AGREEMENT_COMMIT_SUCCEED, result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

// 创建密接单
export function* createCloseScreeningList(action){
   try {
      const result = yield call(api.createCloseScreeningList,action.closeParams);
      if (result.error !== undefined) {
         //请求出错了
         yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
      } else {
         yield put({type: ActionType.CLOSE_SCREENING_LIST_SUCCEED, result});
      }
   }catch (e) {
      yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
   }
}


export function* queryTrackNotice(action){
    try {
       const result = yield call(api.queryTrackNotice,action.params);
       if (result.error !== undefined) {
          //请求出错了
          yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
       } else {
          yield put({type: ActionType.QUERY_TRACK_NOTICE_SUCCEED, result});
       }
    }catch (e) {
       yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* trackFeedbackSubmit(action){
    try {
       const result = yield call(api.trackFeedbackSubmit,action.params);
       if (result.error !== undefined) {
          //请求出错了
          yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
       } else {
          yield put({type: ActionType.TRACK_FEEDBACK_SUBMIT_SUCCEED, result});
       }
    }catch (e) {
       yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* queryTrackFeedback(action){
    try {
       const result = yield call(api.queryTrackFeedback,action.params);
       if (result.error !== undefined) {
          //请求出错了
          yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
       } else {
          yield put({type: ActionType.QUERY_TRACK_FEEDBACK_SUCCEED, result});
       }
    }catch (e) {
       yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* addPatientInfo(action){
    try {
       const result = yield call(api.addPatientInfo,action.patientInfo);
       if (result.error !== undefined) {
          //请求出错了
          yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
       } else {
          yield put({type: ActionType.ADD_PATIENT_INFO_SUCCEED, result});
       }
    }catch (e) {
       yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}


export default function* root() {

    yield takeLatest(ActionType.MY_PATIENTS,getMyPatients); //患者管理-我的患者-患者情况
    yield takeLatest(ActionType.MEDICATION_RECORD_LIST,queryMedicationRecord)//患者管理-我的患者-服药记录

   yield takeLatest(ActionType.BANG_DING_JIE_BANG,queryBindUnbindCommon) //患者管理-患者情况-绑定

   yield takeLatest(ActionType.YAO_HE_UNTYING,queryUntyingCommon) //患者管理-患者情况-解绑

   yield takeLatest(ActionType.YAO_HE_LIST,queryMedicalKitCommon) //患者管理-患者情况-绑定解绑

    yield takeLatest(ActionType.PATIENT_DEATIL,queryPatientDetail) //患者管理-患者情况-患者详情
    yield takeLatest(ActionType.PATIENT_TAKE_MEDICINE,queryPatientTakeMedicineRecord)

    yield takeLatest(ActionType.QUERY_CHECK_SPUTUM,queryCheckSputumRecord) //患者管理-查痰记录

    yield takeLatest(ActionType.POST_MANAGENOTICE,getManageNotice); //患者管理-通知列表

    yield takeLatest(ActionType.POST_MANAGEWAIT,PostManageWait); //患者管理-待随访列表

    yield takeLatest(ActionType.POST_FOLLOWUP_4,stopTreatBill); //患者管理 -通知列表-停止治疗管理通知单
    yield takeLatest(ActionType.FINISH_TREAT,finishTreat); //患者管理 -通知列表-停止治疗管理通知单
    yield takeLatest(ActionType.POST_FOLLOWUP_1,postFollowUpVisitPaper1); //患者管理 -通知列表-新患者确认管理通知单
    yield takeLatest(ActionType.POST_FOLLOWUP_5,postFollowUpVisitPaper5); //患者管理 -通知列表-患者治疗管理反馈单
    yield takeLatest(ActionType.FEEDBACKS_SUBMISSION,postfeedbackSubmission); //患者管理 -通知列表-患者治疗管理反馈单(提交)


   yield takeLatest(ActionType.POST_TAKE_MEDICINE_EVENT,postTakeMedicineEvent); //患者管理 -通知列表-患者治疗管理反馈单(提交)

   yield takeLatest(ActionType.GET_TAKE_MEDICINE_STATUS,getTakeMedicineStatus); //患者管理 -通知列表-患者治疗管理反馈单(提交)


    yield takeLatest(ActionType.POST_FOLLOWPAPER,saveVisitInfo); //患者管理-待随访 - 日常随访
    yield takeLatest(ActionType.QUERY_FIRST_FOLLOWUP,queryFollowUpBasicInfo); //患者管理-待随访 - 查询日常信息


    yield takeLatest(ActionType.POST_FOLLOWPAPERNEWINFO,postFirstFollowUp); //患者管理-待随访 - 首次随访
    yield takeLatest(ActionType.POST_FOLLOWPAPERNEWINFOTWO,followUpVisitPaperNewInfo2); //患者管理-待随访 - 首次随访

    yield takeLatest(ActionType.UPLOAD_FILE,uploadFile) //文件上传
    yield takeLatest(ActionType.FOLLOW_UP_HISTORY,followUpHistory) //随访历史记录

    yield takeLatest(ActionType.STOP_FOLLOW_UP,stopFollowUp)

    yield takeLatest(ActionType.SURE_MANAGE,sureManage)

    yield takeLatest(ActionType.READ,read) //患者管理通知已读
    yield takeLatest(ActionType.CONFIRMSTOPTREAT,confirmStopTreat)//确认停止治疗


    yield takeLatest(ActionType.QUERY_SERVICE_PROTOCOL_INFO,queryServiceProtocolInfo)
    yield takeLatest(ActionType.SERVICE_SIGN_AGREEMENT_COMMIT,commitServiceSignAgreement)

    yield takeLatest(ActionType.CLOSE_SCREENING_LIST,createCloseScreeningList) //密切接触单

    yield takeLatest(ActionType.QUERY_TRACK_NOTICE,queryTrackNotice) //查询追踪单
    yield takeLatest(ActionType.TRACK_FEEDBACK_SUBMIT,trackFeedbackSubmit) //提交追踪反馈
    yield takeLatest(ActionType.QUERY_TRACK_FEEDBACK,queryTrackFeedback) //查询追踪反馈

    yield takeLatest(ActionType.ADD_PATIENT_INFO,addPatientInfo) //查询追踪反馈

}
