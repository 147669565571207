import {call, put, takeLatest} from "redux-saga/effects";
import * as ActionType from "../../actions/patients/login-action";
import loginApi from "../../api/patients/login";

//患者注册
export function* patientRegister(action) {
    try {
        const result = yield call(loginApi.patientRegister, action.patientInfo);
        console.log(result)
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "PATIENT_REGISTER_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者登录
export function* patientLogin(action) {
    try {
        const result = yield call(loginApi.patientLogin, action.patientInfo);

        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "PATIENT_LOGIN0_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}
export function* wxBaseLogin(action) {
   try {
      const result = yield call(loginApi.wxBaseLogin, action.baseInfo);

      if (result.error !== undefined) {
         //请求出错了
         yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
      } else {
         yield put({type: "WX_BASE_LOGIN_SUCCEED", result});
      }
   } catch (e) {
      yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
   }
}

//患者端退出登录
export function* loginCheckoutCenter(action) {
   try {
      const result = yield call(loginApi.loginOutApi, action);
      if (result.error !== undefined) {
         //请求出错了
         yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
      } else {
         yield put({type: "TUI_CHU_DENG_LU_SUCCEED", result});
      }
   } catch (e) {
      yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
   }
}


//患者端我的资料
export function* getLoginView(action) {
    try {
        const result = yield call(loginApi.getLoginView, action.patientId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "PATIENT_LOGIN_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者端手机修改号码
export function* postMobileDiv(action) {
    try {
        const result = yield call(loginApi.postMobileDiv, action.phoneNumber);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "POST_PHONENUMBERS_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}


//患者端我的姓名修改
export function* postNameDiv(action) {
    try {
        const result = yield call(loginApi.postNameDiv, action.patientName);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "POST_NAMEDIVS_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者端修改密码
export function* modifyPatientPwd(action){
    try {
        const result = yield call(loginApi.modifyPatientPwd, action.patientInfo);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "PATIENT_MODIFY_PASSWORD_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//个人端发送短信验证码
export function* sendValidCode(action){
    try {
        const result = yield call(loginApi.sendValidCode, action.data);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "SEND_VALID_CODE_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export default function* root() {
    yield takeLatest(ActionType.PATIENT_REGISTER,patientRegister)
    yield takeLatest(ActionType.WX_BASE_LOGIN,wxBaseLogin)
    yield takeLatest(ActionType.PATIENT_LOGIN0,patientLogin)

    yield takeLatest(ActionType.PATIENT_LOGIN,getLoginView); //患者端-我的资料页登录
    yield takeLatest(ActionType.POST_PHONENUMBERS,postMobileDiv); //患者端-我的手机号修改
    yield takeLatest(ActionType.POST_PHONENUMBERS,postNameDiv); //患者端我的姓名修改提交

    yield takeLatest(ActionType.PATIENT_MODIFY_PASSWORD,modifyPatientPwd)

    yield takeLatest(ActionType.SEND_VALID_CODE,sendValidCode)//发送短信验证码



   yield takeLatest(ActionType.TUI_CHU_DENG_LU,loginCheckoutCenter); //患者端-退出登录

}
