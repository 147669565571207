//患者端主页面
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Route, Switch} from "react-router-dom";
import ChatWindow from './consultDoctor/ChatWindow';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MedicineWidget from './medicineWidget/MedicineWidget';
import ConsultDoctor from './consultDoctor/ConsultDoctor';
import LoginView from './myWidget/LoginView';
import KnowledgeView from './Knowledge/KnowledgeView';
import AddPatient from './AddPatient';

import QrCode from './QrCode';

import wodezhuye from '../assets/images/wodezhuye.png';
import zixunyisheng from '../assets/images/zixunyishneg.png';
import jiankangzhishi from '../assets/images/jiankangzhishi.png';
import zhuye from '../assets/images/zhuye.png';

import HomeIcon from '@material-ui/icons/Home';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PersonIcon from '@material-ui/icons/Person';

//患者端服药康复页面
class MainView extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         bottomValue: 0,
         rows: [],
         showHome: null
      };
   }

   componentDidMount() {
   }

   componentWillUnmount() {
   }

   //fired when props changed
   componentWillReceiveProps(nextProps) {
      if (nextProps.serverInfo !== this.props.serverInfo) {
      }
   }

   render() {
      //get react-material css styles
      const {classes} = this.props;
      let showHomeStatus = '';
      if(window.location.search && window.location.search !== null && window.location.search !== ''){
         showHomeStatus = window.location.search.split("flag=")[1]+'';
      }
      if(showHomeStatus == '2' ){
         this.state.bottomValue = showHomeStatus*1
      }
      //console.log(this.state.bottomValue)
      if(this.state.bottomValue == 2){
         showHomeStatus = '2'
         //console.log("hide")
      }else{
         //console.log("display")
      }
      return (
         <Switch>
            <Route path={`${this.props.match.path}/detail`} component={ConsultDoctor}/>
            {/*<Route path={`${this.props.match.path}/chatWindow`} component={ChatWindow}/>*/}
            <React.Fragment >
               <Grid container className={classes.bodyDiv}>
               {/* 患者康复 健康知识 咨询医生 我的 */}
               {(() => {
                     switch (this.state.bottomValue) {
                        // 服药康复页
                        case 0:
                           return <QrCode history={this.props.history} match={this.props.match}/>;
                        // 健康知识页
                        case 1:
                           //return <AddPatient history={this.props.history} match={this.props.match}/>;
                           const path = `/patient/add`; 
                           const params = {};
                           this.props.history.push(path, params);
                        // 咨询医生页
                        //case 2:
                        //   return <ChatWindow history={this.props.history} match={this.props.match}/>;
                        // 我的
                        case 2:
                           return <LoginView history={this.props.history} match={this.props.match}/>;
                        // 服药康复页
                        default:
                           return <QrCode/>;
                     }
                  }
               )()}
               </Grid>
               {/* 四个主菜单 */}

               <BottomNavigation
                  value={this.state.bottomValue}
                  onChange={(event, newValue) => {
                     this.setState({bottomValue: newValue,})
                     if(newValue == 2){
                        this.setState({showHome: '2'})
                     }
                  }}
                  showLabels
                  className={classes.footer}
               >
                  <BottomNavigationAction label="首页" icon={<HomeIcon  />} />
                  <BottomNavigationAction label="" icon={<AddBoxIcon />} />
                  <BottomNavigationAction label="我的" icon={<PersonIcon />} />
               </BottomNavigation>


               
            </React.Fragment>
         </Switch>
      );
   }

}

const mapStateToProps = (state) => {
   return {}
}

const mapDispatchToProps = (dispatch) => {
   return {
      login: (userInfo) => {
         // dispatch(loginRequest(userInfo))
      },
   }
}


//css region
const styles = theme => ({
   rootDiv: {
      background: '#ffffff',

   },
   bodyDiv: {
      width: '100vw',
      height: '92vh',
      backgroundColor: '#F2F2F2',
   },
   footer: {
      //height: '8vh',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
   },

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainView));
