import React from 'react';
import {connect} from 'react-redux';
import {createMuiTheme, withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import touxiang from '../../assets/images/touxiang.png';
import Link from '@material-ui/core/Link';
import {Avatar, Grid} from '@material-ui/core';
import {Route, Switch} from "react-router-dom";
import SettingView from './SettingView';
import AcographyWidget from '../medicineWidget/AcographyWidget'
import {getLoginView} from "../../redux/actions/patients/login-action";
import jiantoua from '../../assets/images/jiantoua.png'
import ChatWindow from '../consultDoctor/ChatWindow'
import MyScore from './MyScoreWidget'



//患者端我的登陆页
class LoginView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0,
            patientId: localStorage.getItem("patientId"),
            userId: localStorage.getItem("userId")
        };
        this.handleNameClick = this.handleNameClick.bind(this);
        this.handleadvisoryClick = this.handleadvisoryClick.bind(this);
        this.handlezhiliao = this.handlezhiliao.bind(this);
        this.handlejifen = this.handlejifen.bind(this)
    }

    //点击设置
    handleNameClick(info) {
        const path = `${this.props.match.path}/setting`;

        this.props.history.push(path, {"mobile":info.mobile,"realName":info.realName} );
    }

    //点击我的治疗
    handlezhiliao() {
        const path = `${this.props.match.path}/acography`;
        this.props.history.push(path);
    }
    //点击我的积分
    handlejifen() {
        //const path = `${this.props.match.path}/myscore`;
        const path = `/jf`;
        this.props.history.push(path);
    }

    //点击我的咨询
    handleadvisoryClick() {
        const path = `${this.props.match.path}/advisory`;
        this.props.history.push(path);
    }

    componentDidMount() {
        let userId = localStorage.getItem("userId")
        this.props.getLoginView(userId);
    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    handleChange(event, newValue) {
        this.setState({value: newValue});
    }

    handleChangeIndex(index) {
        this.setState({index: index});
    }

    render() {
        //get react-material css styles
        const {classes, loginView} = this.props;
        return (
            <Switch>
                <Route path={`${this.props.match.path}/setting`} component={SettingView}/>
                {/*<Route path={`${this.props.match.path}/phone`} component={PhoneLogin}/>*/}
                <Route path={`${this.props.match.path}/acography`} component={AcographyWidget}/>
                <Route path={`${this.props.match.path}/advisory`} component={ChatWindow}/>
                {/*<Route path={`${this.props.match.path}/myscore`} component={MyScore}/>*/}

                <Grid className={classes.root}>

                    <Grid container item xs={12} justify="center" alignContent="center" className={classes.headerDiv}>
                        <h4 className={classes.defaultLiao}>我的资料</h4>
                    </Grid>
                    {/* <Typography h5 noWrap className={classes.spacemargin}></Typography> */}


                    <Grid container justify="flex-start">

                        <Grid container justify="flex-start" alignContent="center" className={classes.namesDiv}>
                            <Grid item xs={3}>
                                <Avatar className={classes.avatar}><img src={touxiang} className={classes.imgDiv}></img>
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.titleDiv}
                                            onClick={this.handleLoginClick}>{loginView.mobile}</Typography>
                            </Grid>
                        </Grid>

                        {/* <Typography h5 noWrap className={classes.spacemargin}></Typography> */}


                        <Grid className={classes.mainDiv}>
                            {/* <hr className={classes.hr}></hr> */}
                            <Grid container justify="space-between" wrap="nowrap" direction="row"
                                  className={classes.nameDiv}>
                                <Grid item>
                                    <Typography className={classes.titlea} color="textSecondary" gutterBottom>
                                        姓名
                                    </Typography>

                                </Grid>
                                <Grid item>
                                    <Typography className={classes.titlea} color="textSecondary" gutterBottom>
                                        <Link className={classes.span}>{loginView.realName}</Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <hr className={classes.hr}></hr>
                            <Grid container justify="space-between" wrap="nowrap" direction="row"
                                  className={classes.nameDiv}>
                                <Grid item>
                                    <Typography className={classes.title} gutterBottom>
                                        手机号
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.title} gutterBottom>
                                        <Link className={classes.span}>{loginView.mobile}</Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                            
                            {/*
                            <hr className={classes.hr}></hr>

                            <Grid container justify="space-between" wrap="nowrap" direction="row"
                                  className={classes.nameDiv}>
                                <Grid item>
                                    <Typography className={classes.title} gutterBottom>
                                        我的报告
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.title} gutterBottom>
                                        <Link className={classes.span}><img className={classes.jiantoua}
                                                                            src={jiantoua}/></Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <hr className={classes.hr}></hr>

                            <Grid container justify="space-between" wrap="nowrap" direction="row"
                                  className={classes.nameDiv}>
                                <Grid item>
                                    <Typography className={classes.title} gutterBottom >
                                        我的咨询
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.title} gutterBottom>
                                        <Link className={classes.span}><img className={classes.jiantoua}
                                                                            src={jiantoua} onClick={() => {
                                            this.handleadvisoryClick()
                                        }}/></Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <hr className={classes.hr}></hr>
                            <Grid container justify="space-between" wrap="nowrap" direction="row"
                                  className={classes.nameDiv}>
                                <Grid item>
                                    <Typography className={classes.titles} gutterBottom>
                                        我的积分
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        <Link onClick={this.handlejifen} className={classes.span}><img
                                            className={classes.jiantoua} src={jiantoua}/></Link>
                                    </Typography>
                                </Grid>

                            </Grid>
                            **/}
                            {/**
                            <Grid container justify="space-between" wrap="nowrap" direction="row"
                                  className={classes.nameDiv}>
                                <Grid item>
                                    <Typography className={classes.titles} gutterBottom>
                                        我的治疗
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        <Link onClick={this.handlezhiliao} className={classes.span}><img
                                            className={classes.jiantoua} src={jiantoua}/></Link>
                                    </Typography>
                                </Grid>

                            </Grid>

                            {/* <hr className={classes.hr}></hr> */}
                        </Grid>


                        <Typography className={classes.spacemargin}></Typography>


                        <Grid container justify="space-between" wrap="nowrap" direction="row"
                              className={classes.settingDiv}>
                            <Grid container justify="space-between" wrap="nowrap" direction="row"
                                  className={classes.nameDiv}>
                                <Grid item>
                                    <Typography className={classes.titled} gutterBottom>
                                        设置
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.titled} gutterBottom>
                                        <Link onClick={() => {
                                            this.handleNameClick(loginView)
                                        }}  className={classes.span}><img
                                            className={classes.jiantoua} src={jiantoua}/></Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <hr className={classes.hr}></hr>
                        </Grid>
                    </Grid>
                </Grid>
            </Switch>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        patientId: state.patientLoginReducer.patientId,
        loginView: state.patientLoginReducer.loginView,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        getLoginView: (patientId) => {
            dispatch(getLoginView(patientId))
        }
    }
}
const theme = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiTypography: {
            body1: {
                fontSize: '4.2vw',
                paddingRight: '5vw',
                lineHeight: '7vw'
            },
        },
    },
});


//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        height: '100vh',
        backgroundColor: '#ededed'

    },
    nameDiv: {
        backgroundColor: '#ffffff',
        paddingTop: '0.8vw',
        lineHeight: '10vw',
        width: '100vw',
        margin: '0'
    },
   defaultLiao: {
      color: 'rgba(255,255,255,1)',
      fontSize: '4vw'
   },
    imgDiv: {
        width: '7vw',
        height: '7vw',
    },

    namesDiv: {
        backgroundColor: '#ffffff',
        marginBottom: '3vw',
        height: '40vw',
        paddingTop: theme.spacing(5),

    },

    spacemargin: {
        padding: '0',
        color: '#ededed',
        height: '2vw',
        backgroundColor: '#ededed',
    },

    titleDiv: {
        marginTop: theme.spacing(2),
        fontSize: '5vw'

    },

    headerDiv: {
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to left,#00c9e4, 50%, #00dfd7)',
        color: '#ffffff',
    },
    span: {
        marginRight: theme.spacing(2),
        color: '#999999'
    },

    title: {
        marginLeft: theme.spacing(2),
        fontSize: '4.2vw',
        lineHeight: '10vw',
        Height: '10vw',
        color: '#333333',
        padding: 0,
        marginBottom: '0',
        backgroundColor: '#ffffff'
    },

    titled: {
        marginLeft: theme.spacing(2),
        fontSize: '4.2vw',
        lineHeight: '13vw',
        Height: '13vw',
        color: '#333333',
        padding: 0,
        backgroundColor: '#ffffff'
    },

    titles: {
        marginLeft: theme.spacing(2),
        fontSize: '4.2vw',
        lineHeight: '10vw',
        Height: '10vw',
        color: '#333333',
        padding: 0,
        marginBottom: '2.2vw',
        backgroundColor: '#ffffff'

    },

    titlea: {
        marginLeft: theme.spacing(2),
        fontSize: '4.2vw',
        lineHeight: '12vw',
        Height: '12vw',
        color: '#333333',
        marginBottom: '0',
        padding: 0,
        marginTop: '1.1vw',
        backgroundColor: '#ffffff'
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },
    marginDiv: {
        margin: theme.spacing(2),
    },

    paper: {
        height: '33vw',
        borderRadius: '8px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        boxShadow: '1px 1px 1px 2px rgba(0, 0, 0, 0.05)',
    },

    headPortrait: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '29vw',
        justifyContent: 'space-between',
        marginLeft: theme.spacing(0, 3)
    },

    avatar: {
        width: '17vw',
        height: '17vw',
        maxWidth: '20vw',
        maxHeight: '20vw',
        backgroundColor: '#ededed',
        marginLeft: theme.spacing(2),
    },

    mainDiv: {
        backgroundColor: '#FFFFFF',
        lineHeight: '10vw',
        padding: 0,
        marginBottom: '3vw',
    },

    settingDiv: {
        backgroundColor: '#FFFFFF',
        height: '14vw',
        lineHeight: '14vw',
        marginBottom: theme.spacing(0),
        padding: '0vw,'
    },

    jiantoua: {
        height: '1.7vh',
        lineHeight: '1.7vh',
        marginLeft: '1vw',
    },

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginView));
