import React from 'react';
import logo from '../assets/images/yidudao.png';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {wxBaseLogin, getLoginView, sendValidCode, patientRegister } from '../redux/actions/patients/login-action';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { purple } from '@material-ui/core/colors';
import yonghu from '../assets/images/yonghu.png';
import mima from '../assets/images/mima.png';
import email from '../assets/images/email.png';
import gongzuo from '../assets/images/gongzuo.png';
import Grid from '@material-ui/core/Grid';
import MainView from './MainView';
import { Route, Switch } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Link from "@material-ui/core/Link/Link";
import registered from "./registered";
import Toast from "antd-mobile/lib/toast";
import Api from '../redux/api/api';
import { Spin } from 'antd';
import md5 from 'md5'
import TencentCaptcha from '@carpenter/react-tencent-captcha';
import Checkbox from '@material-ui/core/Checkbox';



class PhoneReg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            loginDisabled: false,
            phone: '',
            passwd: '',
            loading: false,
            agreement: false
        };

        //绑定事件
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handlePasswdChange = this.handlePasswdChange.bind(this);
        this.handleAgreementChange = this.handleAgreementChange.bind(this);

        this.captchaCallback = this.captchaCallback.bind(this);
        this.handleSendClick = this.handleSendClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handRegistered = this.handRegistered.bind(this);

    }

    onRefChild = ref => {
        this.captcha = ref;
    }

    captchaCallback = res => {
        console.log(res);

        if (res.ret !== 0 || res.ticket === undefined || res.ticket?.length < 8) {
            return;
        }
        let ts = new Date().getTime()
        let flag = this.inputCheck();
        const loginInfo = {
            loginName: this.state.phone,
            password: this.state.passwd,
            type: 3,//1为医生 3为个人端
            captcha: res,
            ts: ts,
            ticket: res.ticket,
            as: md5(this.state.passwd + ts + res.ticket)
        }
        if (flag) { this.props.sendCode(loginInfo); }
    }

    // 销毁
    destroy = () => {
        this.captcha.destroy();
    }

    componentDidMount() {
        /*
        const location = useLocation();
        const queryParams = new URLSearchParams(location.search);
        const sccid = queryParams.get('sccid');
        localStorage.setItem('sccid',sccid);
        const schoolName = queryParams.get('schoolName');
        localStorage.setItem('schoolName',schoolName);
        this.state.schoolName = queryParams.get('schoolName');
        */
        const urlParams = new URLSearchParams(window.location.search);
        const sccid = urlParams.get('sccid');
        if(sccid == null){
            Toast.show('请扫描学校二维码进行注册！');
            return;
        }
        localStorage.setItem('sccid',sccid);
        const schoolName = urlParams.get('schoolName');
        localStorage.setItem('schoolName',schoolName);
        
        this.state.sccid = sccid;
        //this.state.schoolName = "测试县一中学校";
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        console.log(nextProps.regResult)
        // 判断用户密码是否正确
        if (nextProps.regResult != this.props.regResult) {
            if (nextProps.regResult?.code === 200) {
                //成功注册,则转到学生信息新增页面
                localStorage.setItem("userId",nextProps.regResult.data.userId)
                localStorage.setItem("token",nextProps.regResult.data.token)
                localStorage.setItem("hospitalId",nextProps.regResult.data.hospitalId)
                localStorage.setItem("hospitalName",nextProps.regResult.data.hospitalName)
                const path = `/patient/add`;
                this.props.history.push(path);
            }else if (nextProps.regResult?.code === 201) {
                Toast.show("注册失败!" + nextProps.regResult?.msg);
                return false;
            }else{
                Toast.show("注册异常,请稍后再试！");
            }
        }
        // 点击登录后 弹出授权页面
        //if (nextProps.loginResult && nextProps.loginResult.url) {
        /*let appId = 'wx02a912ac78edd27a';
        let redirectUri = encodeURIComponent('http://wx.yidudao.cn/patient');
        let hrefUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize' +
           '?appid=' + appId +
           '&redirect_uri=' + redirectUri +
           '&response_type=code&scope=snsapi_base' +
           '&state=' + nextProps.loginResult.id +
           '&connect_redirect=1#wechat_redirect';
        // 患者端跳转授权页面
        window.location.href = hrefUrl;*/
        //window.location.href = nextProps.loginResult.url;
        //}
        // 判断二次登录后的openId是否一致
        if (nextProps.loginView != this.props.loginView && nextProps.loginView.openId != undefined && nextProps.loginView.openId != '') {
            let wxOpenId = localStorage.getItem("openId");
            if (nextProps.loginView.openId == wxOpenId) {
                console.log("loginView pass")
                this.props.history.replace(`/patientIndex`);
            } else {
                console.log("what~~");
                localStorage.clear();
                return false;
            }
        }

        if (nextProps.validCodeResult != this.props.validCodeResult) {
            console.log(nextProps.validCodeResult)
            if (nextProps.validCodeResult != undefined && nextProps.validCodeResult.code == 200) {
                Toast.show("发送成功,请留意短信！");
            }else if (nextProps.validCodeResult.code == 201) {
                Toast.show("发送失败！" + nextProps.validCodeResult.msg);
            }else{
                Toast.show("发送异常，请稍后再试!");
            }

        }

    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });

    }


    handleNameChange(event) {
        this.setState({
            phone: event.target.value
        })
    }

    handlePasswdChange(event) {
        this.setState({
            passwd: event.target.value,
        })
    }

    handleAgreementChange(event) {
        console.log(event)
        this.setState({
            agreement: this.state.agreement ? false : true
        })
    }

    //触发发送验证码点击事件
    handleSendClick() {
    }

    //非空检验
    inputCheck() {
        if(this.state.sccid == null || this.state.sccid == ''){
            Toast.show("请扫描学校二维码注册!");
            return false;
        }
        if (this.state.phone == undefined || this.state.phone == '') {
            Toast.show("手机号不能为空!");
            return false;
        }
        if (this.state.passwd == '') {
            Toast.show("密码不能为空!");
            return false;
        }
        //if (!this.state.agreement) { Toast.show("请选择同意《隐私政策》!"); return false; }
        console.log(this.state.phone)
        const phoneNumberPattern = /^1[3456789]\d{9}$/;
        if(!phoneNumberPattern.test(this.state.phone)){
            Toast.show("手机号格式不正确！");
            return false;
        }
        return true;
    }



    //点击个人端注册页面
    handRegistered() {
        let flag = this.inputCheck();
        if(!flag) return;
        const regInfo = {
            loginName: this.state.phone,
            password: this.state.passwd,
            type: 3,//1为医生 3为个人
            code: this.state.code,
            sccid: this.state.sccid,
        }
        this.props.registered(regInfo);
    }

    render() {
        const { classes } = this.props;
        return (
            <Switch>
                <Route path={`${this.props.match.path}/Login`} component={MainView} />
                <Route path={`${this.props.match.path}/registered`} component={registered} />


                <Grid container className={classes.root}>
                    <Grid item justify="center" wrap="nowrap" spacing={3}>
                        <img src={logo} alt="" className={classes.tupian} />
                    </Grid>

                    <Grid>
                        <Typography className={classes.typoText} gutterBottom>
                            个人端注册
                        </Typography>
                    </Grid>

                    <Grid item justify="center" wrap="nowrap" spacing={2}>
                        <Grid className={classes.myno}>
                            <img src={yonghu} className={classes.email} />
                            <YddInput
                                name="phone"
                                placeholder="请输入手机号"
                                value={this.state.phone}
                                onChange={this.handleNameChange}
                                autoComplete='off'
                            />
                        </Grid>
                    </Grid>

                    <Grid item justify="center" wrap="nowrap" spacing={2}>
                        <Grid className={classes.myPassword}>
                            <img src={mima} className={classes.passwordpicture} />
                            <YddInput
                                name="idcardNum"
                                placeholder="请输入密码"
                                type="password"
                                value={this.state.passwd}
                                onChange={this.handlePasswdChange}
                                autoComplete='off'
                            />
                        </Grid>
                    </Grid>

                    <Grid item justify="center" wrap="nowrap" spacing={0} container>
                        <Grid item className={classes.myCode}>
                            <img src={email} className={classes.passwordpicture} />
                            <YddInput
                                name="code"
                                placeholder="短信验证码"
                                value={this.state.code}
                                onChange={this.handleInputChange}
                            />
                        </Grid>
                        <Grid item className={classes.myCodeBtn}>
                            <TencentCaptcha
                                appid="190688974"
                                callback={this.captchaCallback}
                                onRefChild={this.onRefChild}
                            >
                                <ColorButton
                                    onClick2={this.handleSendClick}
                                    variant="contained"
                                    color="primary"
                                    className={classes.sendBtn}
                                >
                                    发送
                                </ColorButton>
                            </TencentCaptcha>
                        </Grid>
                    </Grid>

                    <Grid item justify="center" wrap="nowrap" spacing={2} className={classes.hideMe}>
                        <Grid className={classes.agreement}>

                            <Typography className={classes.agreementText}>
                                学校：{this.state.schoolName}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item justify="center" wrap="nowrap" spacing={2}>

                            <ColorButton
                                onClick={this.handRegistered}
                                variant="contained"
                                color="primary"
                                className={classes.margin}
                            >
                                注 &nbsp;&nbsp; 册
                            </ColorButton>

                    </Grid>
                </Grid>

            </Switch>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        validCodeResult: state.patientLoginReducer.validCodeResult,
        regResult: state.patientLoginReducer.regResult,
        baseInfoResult: state.patientLoginReducer.baseInfoResult,
        loginView: state.patientLoginReducer.loginView
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendCode: (data) => {
            dispatch(sendValidCode(data))
        },
        registered: (regInfo) => {
            dispatch(patientRegister(regInfo))
        },
        getLoginView: (patientId) => {
            dispatch(getLoginView(patientId))
        }
    }
}


//css region
const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100vw',
        height: '100vh',
        // marginTop: '3vw',
        alignItems: 'center',
        color: '#333333',
        backgroundColor: '#ffffff',

    },
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },

    warning: {
        marginTop: theme.spacing(68),
    },

    click: {
        color: '#00d5dd',
        fontSize: '3.5vw',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(32),
    },

    message: {
        display: 'flex',
        alignItems: 'center',
    },

    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    icon: {
        fontSize: 20,
    },


    tupian: {
        width: '40vw',
        marginBottom: '2vw',
        marginTop: '29vw',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },

    typoText: {
        fontSize: '4.5vw',
        marginBottom: '15vw',
        color: '#00d5dd',
        fontWeight: '550',
        letterSpacing: '1.5vw',
    },

    loginWayDiv: {
        display: 'flex',
        justifyContent: 'flex-start',
    },

    forgetPasswdDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    classNameLoding: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center'
    },

    myno: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1.5vw',
        borderRadius: '1vw',
        flexDirection: 'row',
        width: '85vw',
        height: '12vw',
        border: '0.6px solid #666666',
    },

    email: {
        width: '4.5vw',
        paddingLeft: '1vw',
        height: '5vw',
        marginRight: '2vw',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },

    userName: {
        width: '80vw',
        fontSize: '4vw',
        paddingLeft: '1vw',
        borderRadius: '0.3vw',
        color: '#666666',
        border: '0',
        outline: 'none',
        height: '11vw',
        backgroundColor: 'transparent',
        lineHeight: '11vw',
    },

    myPassword: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '3vw',
        borderRadius: '1vw',
        flexDirection: 'row',
        width: '85vw',
        height: '12vw',
        border: '0.6px solid #666666',
        marginTop: '2vw'
    },

    myCode: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '3vw',
        borderRadius: '1vw',
        flexDirection: 'row',
        width: '45vw',
        height: '12vw',
        border: '0.6px solid #666666',
        marginTop: '2vw'
    },
    sendBtn: {
        border: '0.6px solid #23d76e',
        width: '38vw',
        height: '12vw',
        fontSize: '5vw',
        marginTop: '2vw',
        marginLeft: '2vw',
        borderRadius: '1vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        display: 'flex',
        lineHeight: '1vw',
    },

    passwordpicture: {
        width: '4.5vw',
        paddingLeft: '1vw',
        height: '5vw',
        marginRight: '2vw',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },

    passWord: {
        // width: '80vw',
        fontSize: '4vw',
        paddingLeft: '1vw',
        borderRadius: '0.3vw',
        color: '#a8adaa',
        border: '0',
        outline: 'none',
        height: '11vw',
        backgroundColor: 'transparent',
        lineHeight: '11vw',
    },

    agreement: {
        display: 'flex',
        alignItems: 'left',
        borderRadius: '1vw',
        flexDirection: 'row',
        width: '85vw',
    },
    agreementBox: {
        paddingLeft: '0',
    },
    agreementText: {
        marginTop: '2vw',
    },

    margin: {
        border: '0.6px solid #23d76e',
        width: '85vw',
        height: '11vw',
        fontSize: '5vw',
        marginTop: '10vw',
        borderRadius: '1vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        display: 'flex',
        lineHeight: '1vw',
    },

    modalRoot: {
        height: 300,
        flexGrow: 1,
        transform: 'translateZ(0)',
        '@media all and (-ms-high-contrast: none)': {
            display: 'none',
        },
    },

    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },

    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    hideMe: {
        display: 'none'
    }
});

const ColorButton = withStyles((theme) => ({
    root: {
        width: '40vw',
        height: '8vh',
        fontSize: '3.5vh',
        boxShadow: 'inset 0 0 4px #fff',
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        '&:hover': {},
    },
}))(Button);

const YddInput = withStyles((theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            position: 'relative',
            fontSize: 15,
            width: '66vw',
            height: '9vw',
            borderBottom: '1 solid #666666',
            outline: 'none',
            backgroundColor: 'transparent',
            '&:focus': {
                borderColor: theme.palette.primary.main,
            },
        },
    }),
)(InputBase);
//end css region

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PhoneReg));
