import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { postFirstFollowUp,querySputumCheckRecord, queryFollowUpBasicInfo } from '../redux/actions/patient_manage_action';
import { Route, Switch } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axxxx from '../assets/images/axxxx.png';
import Input from '@material-ui/core/Input';
import SignaturePad from '../lib/signature/index';
import SignPadPlus from '../lib/signPadPlus';
import Toast from "antd-mobile/lib/toast";
import Moment from 'moment';
import Picker from "antd-mobile/lib/picker";
//弹出框

import { Upload, message, Icon } from 'antd';

// 引入antd 时间组件
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import '../assets/css/border.css';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import Checkbox from "@material-ui/core/Checkbox/Checkbox";
let jhjCheckResultIdDefaultValue;
let sputumCheckResultIdDefaultValue;
let patientTypeIdDefaultValue;
let patientTreatTypeIdDefaultValue;


const BeautyFormControlLabel = withStyles({
    root: {
        margin: 0,
    },
    label: {
        fontSize: '1rem',
        marginLeft: '-1.8vw',
        color: 'black',
        fontWeight: 395,
    },
})(FormControlLabel);

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 14,
        height: 14,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 14,
            height: 14,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

const GreenCheckbox = withStyles({
    root: {
        color: "#00C9E4",
        "&$checked": {
            color: "#00C9E4"
        }
    },
    checked: {}
})(props => <Checkbox color="default" {...props} />);

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

// 住址信息选择器
// 如果不是使用 List.Item 作为 children
const CustomChildren = props => (
    <div onClick={props.onClick} style={{paddingLeft: 15}}>
        <div
            className="test"
            style={{display: "flex", height: "13.3vw", lineHeight: "13.3vw"}}
        >
            <div
                style={{
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                }}
            >
                {props.children}
            </div>
            <div style={{textAlign: "right", color: "#888"}}>{props.extra}</div>
        </div>
    </div>
);

//筛查检验信息
class ScreeningCheck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };

        this.handleNavBack = this.handleNavBack.bind(this);

    }

    componentDidMount() {
        this.state.patientId = this.props.location.state?.patientId;
        this.props.getSputumRecord(this.state.patientId);

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

        if (nextProps.sputumRecord !== this.props.sputumRecord) {
            console.log(nextProps.sputumRecord)
            if (nextProps.sputumRecord !== undefined) {
                if (nextProps.sputumRecord.code == 200) {
                    this.setState({
                        schoolName: nextProps.sputumRecord.data?.schoolName,
                        name: nextProps.sputumRecord.data?.name,
                        className: nextProps.sputumRecord.data?.className,
                        ppdData: nextProps.sputumRecord.data?.ppdData,
                        ecData: nextProps.sputumRecord.data?.ecData,
                        igraData: nextProps.sputumRecord.data?.igraData,
                    })
                }
            }
        }
    }

   

    //点击返回
    handleNavBack() {
        this.props.history.goBack();
    }

    render() {
        const { classes} = this.props;
        
        //this.state.name = "徐长发"
        //this.state.className = "2023级高三4班"
        return (
            <Switch>
                {/*<Route path={`${this.props.match.path}/paper5`} component={FollowUpVisitPaper5} />*/}
                <div className={classes.root}>
                    <Grid item xs={12} className={classes.Patient}>
                        <img src={axxxx} alt="" className={classes.jiantou} onClick={this.handleNavBack}></img>
                        <Grid item xs={12} className={classes.headers}><h5 className={classes.huanzhe}>查验表</h5></Grid>
                    </Grid>
                    <Grid className={classes.roots}>
                        <Typography h5 noWrap className={classes.spacemargin}></Typography>
                        <Grid container justify="center" wrap="nowrap" spacing={2}>
                            <Typography className={classes.titleMain} h5 gutterBottom>
                                查验表
                            </Typography>
                        </Grid>
                        <Typography h5 noWrap className={classes.spacemargin}></Typography>
                        <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    学校：<span className={classes.title}>{this.state.schoolName}</span>
                                </Typography>
                            </Grid>

                        </Grid>
                        
                        <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    姓名：<span className={classes.title}>{this.state.name}</span>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    班级：<span className={classes.title}>{this.state.className}</span>
                                </Typography>
                            </Grid>

                        </Grid>

                        <div className={this.state.ppdData == null?classes.hideMe:''}>
                        <hr className={classes.hr}></hr>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    PPD反应：<span className={classes.span}>{this.state.ppdData?.ppdReact==1?"有":""}{this.state.ppdData?.ppdReact==0?"无":""}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                硬结横径（mm）：<span className={classes.span}>{this.state.ppdData?.ppdReact==1?this.state.ppdData?.ppdHardenDiameterX:""}{this.state.ppdData?.ppdReact==0?"无反应":""}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                硬结纵径（mm）：<span className={classes.span}>{this.state.ppdData?.ppdReact==1?this.state.ppdData?.ppdHardenDiameterY:""}{this.state.ppdData?.ppdReact==0?"无反应":""}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                硬结平均直径（mm）：<span className={classes.span}>{this.state.ppdData?.ppdReact==1?this.state.ppdData?.ppdHardenDiameter:""}{this.state.ppdData?.ppdReact==0?"无反应":""}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    阳性类型：<span className={classes.span}>
                                        <b className={this.state.ppdData?.ppdReact==1?'':classes.hideMe}>
                                            {this.state.ppdData?.ppdBlister==1?" 水泡":""}
                                            {this.state.ppdData?.ppdDoubleLoop==1?" 双圈":""}
                                            {this.state.ppdData?.ppdNecrosis==1?" 坏死":""}
                                            {this.state.ppdData?.ppdLymphangitis==1?"  淋巴管炎":""}
                                        </b>
                                        {this.state.ppdData?.ppdReact==0?"无反应":""}
                                        </span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    PPD结果判定：<span className={classes.span}>
                                        <b className={this.state.ppdData?.ppdReact==1?'':classes.hideMe}>
                                            {this.state.ppdData?.ppdResult==0?"  未检查":""}
                                            {this.state.ppdData?.ppdResult==1?"  阴性":""}
                                            {this.state.ppdData?.ppdResult==2?"  一般阳性":""}
                                            {this.state.ppdData?.ppdResult==3?"  中阳":""}
                                            {this.state.ppdData?.ppdResult==4?"  强阳":""}
                                        </b>
                                        {this.state.ppdData?.ppdReact==0?"阴性":""}
                                        </span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    备注：<span className={classes.span}>{this.state.ppdData?.ppdRemark}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        </div>

                        <div className={this.state.ecData == null?classes.hideMe:''}>
                        <hr className={classes.hr}></hr>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    EC反应：<span className={classes.span}>{this.state.ecData?.ecReact==1?"有":""}{this.state.ecData?.ecReact==0?"无":""}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                EC硬结或红晕较大值的平均直径(mm)： <span className={classes.span}>
                                {this.state.ecData?.ecReact==1?this.state.ecData.ecHardenDiameter:""}
                                {this.state.ecData?.ecReact==0?"无反应":""}
                                </span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    阳性类型：<span className={classes.span}>
                                    <b className={this.state.ecData?.ecReact==1?'':classes.hideMe}>
                                        {this.state.ecData?.ecBlister==1?"  水泡":""}
                                        {this.state.ecData?.ecDoubleLoop==1?"  双圈":""}
                                        {this.state.ecData?.ecNecrosis==1?"  坏死":""}
                                        {this.state.ecData?.ecLymphangitis==1?"  淋巴管炎":""}
                                    </b>
                                    {this.state.ecData?.ecReact==0?"无反应":""}
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    EC结果判定：<span className={classes.span}>
                                    <b className={this.state.ecData?.ecResult==1?'':classes.hideMe}>
                                        {this.state.ecData?.ecResult==0?"  未检查":""}
                                        {this.state.ecData?.ecResult==1?"  阳性":""}
                                        {this.state.ecData?.ecResult==2?"  阴性":""}
                                    </b>
                                    {this.state.ecData?.ecResult==0?"阴性":""}
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    备注：<span className={classes.span}>{this.state.ecData?.ecRemark}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        </div>

                        <div className={this.state.igraDataData == null?classes.hideMe:''}>
                        <hr className={classes.hr}></hr> 
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    IGRA检查时间：<span className={classes.span}>{this.state.ppdData?.ppdRemark}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    IGRA结果判定：<span className={classes.span}>
                                    <b className={this.state.igraData?.igraResult > 0?'':classes.hideMe}>
                                        {this.state.igraData?.igraResult==0?"  未检查":""}
                                        {this.state.igraData?.igraResult==1?"  阳性":""}
                                        {this.state.igraData?.igraResult==2?"  阴性":""}
                                        {this.state.igraData?.igraResult==3?"  不确定":""}
                                    </b>
                                    {this.state.igraData?.igraResult==0?"阴性":""}
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    备注：<span className={classes.span}>{this.state.igraData?.igraRemark}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        </div>

                    </Grid>
                </div>
            </Switch>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        followUpBasic: state.patientManageReducer.followUpBasicInfo,
        fileUrl: state.patientManageReducer.fileUrl,
        followupId: state.patientManageReducer.firstFollowUpId,
        sputumRecord: state.patientManageReducer.sputumRecord,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //预约查痰记录
        getSputumRecord: (patientId) => {
            dispatch(querySputumCheckRecord(patientId))
        },
        //查询随访基本信息
        queryFollowUpBasicInfo: (patientId) => {
            dispatch(queryFollowUpBasicInfo(patientId))
        },
        //提交日常随访信息
        addFirstFollowUp: followUpInfo => {
            dispatch(postFirstFollowUp(followUpInfo))
        }
    }
}


//css region
const styles = theme => ({
    // 时间组件样式
    titleBoldBox: {
        marginLeft: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333',
        display: 'flex',
        // justifyContent: 'center'
    },
    pickersInputBorder: {
        border: 0,
    },
    timeContentBoxRight: {
        width: "60%"
    },
    textFieldContent: {
        width: '60vw'
    },
    /**************** 分割线 **************/
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        background: '#ffffff',
        minHeight: '100vh',
    },

    roots: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },

    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },

    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },

    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },

    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },

    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },

    file: {
        display: 'none',
        visibility: 'hidden',
    },

    buttonsTwo: {
        width: '25vw',
        height: '8vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '3vw',
        marginBottom: 'theme.spacing(2)',

        margin: theme.spacing(1),
        position: 'relative',
        border: '1px solid #00C9E4',
        borderRadius: '5px',
        padding: '4px 12px',
        overflow: 'hidden',
        color: '#fff',
        textDecoration: 'none',
        lineHeight: '8vw'
    },

    buttons: {
        width: '95vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginBottom: '10vw',
        fontSize: '4.5vw',
        marginTop: '5vw'
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },

    textField: {
        marginTop: theme.spacing(0),
        width: '90vw',
    },

    input: {
        margin: theme.spacing(0),
        width: '11vw'
    },

    inputs: {
        width: '90vw',
        marginBottom: theme.spacing(2),
    },

    rootDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
    },

    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },

    title1: {
        color: '#000000',
        fontSize: '4vw',
        fontWeight: '500',
    },

    span: {
        float: 'right'
    },

    titleBold: {
        marginLeft: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333'
    },

    titleButton: {
        marginLeft: theme.spacing(10),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },

    titleBoldDiv: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },

    titleTime: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333'
    },

    titleMain: {
        fontSize: '5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },

    titleBolds: {
        marginBottom: 0,
        fontSize: '4.2vw',
        lineHeight: '8vw',
        color: '#333333'
    },

    title: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '500',
        color: '#333333'
    },

    margin: {
        margin: theme.spacing(2),
    },

    spacemargin: {
        margin: theme.spacing(2),

    },

    titleBoldss: {
        marginTop: theme.spacing(2),
        fontSize: '4.2vw',
        color: '#333333'

    },

    doctor: {
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '700',
        color: '#333333'
    },

    inputName: {
        marginBottom: theme.spacing(1),
        fontWeight: '500',
        width: '67vw'
    },

    photosDIV: {
        marginBottom: theme.spacing(2),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },
    hideMe: {
        display: 'none'
    },
    dataDiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "90vw",
        height: "13.3vw",
        fontSize: "4.2vw",
    },

});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScreeningCheck));
