const Api = {
   getRootUrl() {
      return '';
      //return 'http://127.0.0.1:8600';
   },
   /**
    * Populates the users, similar to seeding a database in the real world
    */
   init(appname, serveraddr, api_key, proto) {

   },


   //医生端-登录action
   doctorLogin(loginInfo) {
      //初始化header对象
      let httpHeader = new Headers();
      //设置header属性
      httpHeader.append('Accept', 'application/json, */*') //设置json请求头
      httpHeader.append('Content-Type', 'application/json;charset=UTF-8')  //请求头为utf-8编码的json格式参数

      let url = Api.getRootUrl() + "/api/user/login";
      const request = {
         method: 'POST',
         headers: httpHeader,
         body: JSON.stringify(loginInfo)
      }

      return fetch(url, request).then(res => res.json()).then(data => {
         return data;
      }).catch(e => {
         return {error: e};
      })

   },

   //患者推介-我的推介
   getMyIntroduceList(doctorId) {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')
      let userId = localStorage.getItem("userId");
      let url = Api.getRootUrl() + '/api/introduce/getIntroduceList?userId=' + userId;

      const init = {
         method: 'GET',
         headers: initHeaders
      }
      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data.data;
         })
         .catch(e => {
            return {error: e};
         })
   },

   //患者推介 - 新建推介
   submitIntroduceNewInfo(introNewInfo) {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

      let data = introNewInfo;

      let postData = {};
      postData.name = data.name;
      postData.iDNumber = data.idcardNum;
      postData.sex = data.gender == "male" ? 1 : 2;
      postData.age = data.age;
      postData.telphone = data.phoneNum;
      postData.regionIds = data.regionIds;
      postData.isSend = data.isSend;
      postData.detailAddress = data.detailAddress;
      postData.reasonId = data.introduceReason;
      postData.userId = localStorage.getItem("userId");

      let url = Api.getRootUrl() + '/api/introduce/addIntroduce';
      const init = {
         method: 'POST',
         headers: initHeaders,
         body: JSON.stringify(postData)
      }

      return fetch(url, init).then(res => res.json()).then(data => {
         return data;
      }).catch(e => {
         return {error: e};
      })

   },

   //患者推介 - 我的推介 - 查看详情
   introduceDetail(introId) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/introduce/getIntroduceById?id=' + introId;
      const init = {
         method: 'GET',
         headers: headers
      }
      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data.data;
         })
         .catch(e => {
            return {error: e};
         })
   },

   // 获取住址信息
   getRegionListInfo() {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')
      let url = Api.getRootUrl() + '/api/region/listByUserId?userId=' + localStorage.getItem("userId");
      const init = {
         method: 'GET',
         headers: initHeaders,
      }
      return fetch(url, init).then(res => res.json()).then(data => {
         return data;
      }).catch(e => {
         return {error: e};
      })
   },

   // 获取住址信息
   getClassListInfo() {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')
      let url = Api.getRootUrl() + '/api/region/listClassByUserId?userId=' + localStorage.getItem("userId");
      if(localStorage.getItem("hospitalId") != null){
         url = Api.getRootUrl() + '/api/region/listClassBySchoolId?schoolId=' + localStorage.getItem("hospitalId");
      }
      if(localStorage.getItem("schoolId") != null){
         url = Api.getRootUrl() + '/api/region/listClassBySchoolId?schoolId=' + localStorage.getItem("schoolId");
      }
      const init = {
         method: 'GET',
         headers: initHeaders,
      }
      return fetch(url, init).then(res => res.json()).then(data => {
         return data;
      }).catch(e => {
         return {error: e};
      })
   },

   // 患者推介，获取推介原因列表
   getReasonListInfo() {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')
      let url = Api.getRootUrl() + '/api/reason/listByUserId?type=1&userId=' + localStorage.getItem("userId");
      const init = {
         method: 'GET',
         headers: initHeaders,
      }
      return fetch(url, init).then(res => res.json()).then(data => {
         return data;
      }).catch(e => {
         return {error: e};
      })
   },

   //患者推介，发送推介码
   submitSendIntroduceInfo(appointInfo) {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

      let data = appointInfo;
      let body = JSON.stringify(data, null, 2)
      let url = Api.getRootUrl() + '/sendintroduceinfo';
      const init = {
         method: 'POST',
         headers: initHeaders,
         body
      }
      return fetch(
         url,
         init
      )
         .then(res => res.json())
         .then(data => {
            return data.message;
         })
         .catch(e => {
            return {error: e};
         })

   },

   //患者推介->通知 列表数据
   getNoticeList(noticeType) {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')
      let userId = localStorage.getItem("userId");
      let url = Api.getRootUrl() + `/api/notice/getNoticeInfo?noticeType=${noticeType}&userId=${userId}`;

      const init = {
         method: 'GET',
         headers: initHeaders
      }

      return fetch(url, init).then(res => res.json()).then(data => {
         return data.data.data;
      }).catch(e => {
         return {error: e};
      })
   },

   //通知已读
   noticeReaded(notice) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + `/api/notice/readNotice?id=${notice.id}`;

      const init = {
         method: 'GET',
         headers: headers
      }

      return fetch(url, init)
         .then(res => res.json())
         .then(data => {
            return data.data.data;
         }).catch(e => {
            return {error: e};
         })
   },

   //患者推介 -通知 -查看详情
   getIntroduceNoticeDetail(noticeId) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/introduce/getIntroduceById?id=' + noticeId;
      const init = {
         method: 'GET',
         headers: headers
      }
      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data.data;
         })
         .catch(e => {
            return {error: e};
         })
   },

   getIntroduceNotice7Days(noticeId) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      //url代表的是从远程服务器请求数据
      let url = Api.getRootUrl() + '/api/introduce/getIntroduceById?id=' + noticeId;
      const init = {
         method: 'GET',
         headers: headers
      }
      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data.data;
         })
         .catch(e => {
            return {error: e};
         })
   },

   //患者推介 -通知 -已就诊
   getIntroduceNotifyTreated(noticeId) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      //url代表的是从远程服务器请求数据
      let url = Api.getRootUrl() + '/api/introduce/getIntroduceById?id=' + noticeId;
      const init = {
         method: 'GET',
         headers: headers
      }
      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data.data;
         })
         .catch(e => {
            return {error: e};
         })
   },

   //患者跟踪 - 我的追踪
   getMyTrackList(doctorId) {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/patientTrack/getPatientTrackList?userId=' + localStorage.getItem("userId");
      const init = {
         method: 'GET',
         headers: initHeaders
      }
      return fetch(
         url,
         init
      )
         .then(res => res.json())
         .then(data => {
            return data.data;
         })
         .catch(e => {
            return {error: e};
         })
   },


   getMyTrackNoticeList(noticeType) {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/notice/getNoticeInfo?noticeType=2&userId=' + localStorage.getItem("userId");

      const init = {
         method: 'GET',
         headers: initHeaders,
      }
      return fetch(
         url,
         init
      )
         .then(res => res.json())
         .then(data => {
            return data.data.data;
         })
         .catch(e => {
            return {error: e};
         })
   },

   //患者追踪 -通知 -查看详情
   getTrackAppointment(noticeId) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      //url代表的是从远程服务器请求数据
      let url = Api.getRootUrl() + '/api/patientTrack/getPatientTrackById?id=' + noticeId;
      const init = {
         method: 'GET',
         headers: headers
      }

      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data.data;
         })
         .catch(e => {
            return {error: e};
         })
   },


   //患者追踪 -查看详情-追踪失败
   postTrackFailure(failureReason) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      //url代表的是从远程服务器请求数据
      let body = JSON.stringify(failureReason)
      let url = Api.getRootUrl() + '/api/patientTrack/updatePatientTrackFailById';
      const init = {
         method: 'POST',
         headers: headers,
         body
      }

      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data.code;
         })
         .catch(e => {
            return {error: e};
         })
   },

   //
   submitAppointmentInfo(appointInfo) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      let data = appointInfo;
      let body = JSON.stringify(data)
      let url = Api.getRootUrl() + '/api/patientTrack/updatePatientTrackAdviceById';

      const init = {
         method: 'POST',
         headers: headers,
         body
      }
      return fetch(url, init)
         .then(res => res.json())
         .then(data => {
            return data.code;
         }).catch(e => {
            return {error: e};
         })
   },

   //
   submitAppointmentReserveInfo(appointInfo) {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      // initHeaders.append('Cache-Control', 'no-cache')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

      let data = appointInfo;
      let body = JSON.stringify(data, null, 2)
      let url = Api.getRootUrl() + '/appointreserveinfo';

      const init = {
         method: 'POST',
         headers: initHeaders,
         body
      }
      return fetch(
         url,
         init
      )
         .then(res => res.json())
         .then(data => {
            return data.message;
         })
         .catch(e => {
            return {error: e};
         })

   },

   //获取医生端个人信息
   getMyInfo(myProfile) {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + `/api/doctor/personInfo?id=${myProfile.id}&type=${myProfile.type}`;
      const init = {
         method: 'GET',
         headers: initHeaders,
      }
      return fetch(
         url,
         init
      )
         .then(res => res.json())
         .then(data => {
            return data.data;
         })
         .catch(e => {
            return {error: e};
         })
   },

   //医生端我的资料
   getMyView(userId) {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')
      let url = Api.getRootUrl() + '/api/user/personInfo?type=1&id=' + userId;

      const init = {
         method: 'GET',
         headers: initHeaders
      }

      return fetch(url, init).then(res => res.json())
         .then(data => {
            return data.data;
         })
         .catch(e => {
            return {error: e};
         })
   },


   //医生端我的手机号码修改提交
   postMobileDiv(phoneNumber) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      //url代表的是从远程服务器请求数据
      let url = Api.getRootUrl() + '/api/user/modifyInfo';

      const init = {
         method: 'POST',
         headers: headers,
         body: JSON.stringify(phoneNumber)
      }

      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data.code;
         })
         .catch(e => {
            return {error: e};
         })
   },


   //医生端我的姓名修改提交
   postNameDiv(phoneNumber) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      //url代表的是从远程服务器请求数据
      let url = Api.getRootUrl() + '/api/user/modifyInfo';

      const init = {
         method: 'POST',
         headers: headers,
         body: JSON.stringify(phoneNumber)
      }

      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data.code;
         })
         .catch(e => {
            return {error: e};
         })
   },

   //患者获取基本新闻列表
   getPatientBasicNewsList() {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/preach/list?dataType=2';

      const init = {
         method: 'GET',
         headers: initHeaders
      }

      return fetch(url, init).then(res => res.json()).then(data => {
         return data.data;
      }).catch(e => {
         return {error: e};
      })
   },
   //患者获取消息新闻列表
   getPatientMessageNewsList() {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/preach/list?dataType=1';

      const init = {
         method: 'GET',
         headers: initHeaders
      }

      return fetch(url, init).then(res => res.json()).then(data => {
         return data.data;
      }).catch(e => {
         return {error: e};
      })
   },
   //患者新闻详情
   getPatientNewsDetail(newsId) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/preach/getEducationPreaching?id=' + newsId;

      const init = {
         method: 'GET',
         headers: headers
      }
      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data.data;
         })
         .catch(e => {
            return {error: e};
         })
   },

   //医生获取基本新闻列表
   getDoctorBasicNewsList() {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/preach/list?dataType=3';

      const init = {
         method: 'GET',
         headers: initHeaders
      }

      return fetch(url, init).then(res => res.json()).then(data => {
         return data.data;
      }).catch(e => {
         return {error: e};
      })
   },

   //医生获取消息新闻列表
   getDoctorMessageNewsList() {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/preach/list?dataType=1';

      const init = {
         method: 'GET',
         headers: initHeaders
      }

      return fetch(url, init).then(res => res.json()).then(data => {
         return data.data;
      }).catch(e => {
         return {error: e};
      })
   },
   //医生新闻详情
   getDoctorNewsDetail(newsId) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/preach/getEducationPreaching?id=' + newsId;
      const init = {
         method: 'GET',
         headers: headers
      }
      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data.data;
         })
         .catch(e => {
            return {error: e};
         })
   },

   // 医生端教育宣讲
   getDoctorTeachMessage() {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/preach/list?dataType=3'

      const init = {
         method: 'GET',
         headers: headers
      }
      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data.data;
         })
         .catch(e => {
            return {error: e};
         })
   },

   /**
    * Pretends to register a user
    *
    * @param  {string} username The username of the user
    * @param  {string} password The password of the user
    */
   register(username, password) {

   },
   /**
    * Pretends to log a user out and resolves
    */
   logout() {

   },
   /**
    * Toggle console logging. Logging is off by default.
    * @memberof Tinode#
    * @param {boolean} enabled - Set to <tt>true</tt> to enable logging to console.
    */
   enableLogging: function (enabled, trimLongStrings) {
      this._loggingEnabled = enabled;
      this._trimLongStrings = enabled && trimLongStrings;
   },
}

Api.init()

export default Api
