//common
import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import DoctorOrPatientView from "./DoctorOrPatientView";
//for doctor
import DoctorLoginView from './doctor/LoginView';
import DoctorMainView from './doctor/MainView';
import AuthView from "./doctor/AuthView";
import DoctorWorkView from './doctor/WorkView';
import DoctorWorkJumpView from './doctor/WorkJumpView';
import DoctorCheckView from './doctor/ScreenCheckView';
import PatientDetailView from './doctor/PatientDetailView';
import AddStudentView from './doctor/AddStudent';

import PatientPhoneLogin from './patient/PhoneLogin';

import PatientPhoneReg from './patient/PhoneReg';
import AddPatient from './patient/AddPatient';
import EditPatient from './patient/EditPatient';
import ScreeningAsk from './patient/ScreeningAsk';
import ScreeningAskEdit from './patient/ScreeningAskEdit';
import ScreeningCheck from './patient/ScreeningCheck';

import PatientMainView from './patient/MainView';




function AppRouter() {
  return (
    <Router basename="/">

      <Switch>
      {/*doctor view*/}
        <Route exact path="/" component={DoctorOrPatientView} />
        {/*<Route exact path="/" component={RemindAdd} />*/}
        <Route path="/doctor" component={DoctorLoginView} />
        <Route path="/doctorIndex" component={DoctorMainView} />
        <Route path="/doctorAuth" component={AuthView} />
        <Route path="/doctorWork" component={DoctorWorkView} />
        <Route path="/doctorWorkJump" component={DoctorWorkJumpView} />
        <Route path="/doctorCheck" component={DoctorCheckView} />
        <Route path="/patientDetail" component={PatientDetailView} />
        <Route path="/addStudent" component={AddStudentView} />

        <Route path="/reg" component={PatientPhoneReg} />
       
        {/*patient view*/}
       {/*<Route path="/patient" component={PatientPhoneLogin} />*/}
       <Route path="/patientIndex" component={PatientMainView} />
       <Route path="/editPatient" component={EditPatient} />
       <Router basename="/patient">
          <Route exact path="/" component={PatientPhoneLogin} />
          <Route path="/add" component={AddPatient} />
          <Route path="/ask" component={ScreeningAsk} />
          <Route path="/askEdit" component={ScreeningAskEdit} />
          <Route path="/check" component={ScreeningCheck} />
          {/* 其他路由定义 */}
       </Router>

       
      </Switch>

    </Router>
  );
}


export default AppRouter;
