/* 患者管理主页面 */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { makeStyles, withStyles, withTheme } from '@material-ui/core/styles';
import { Route, Switch } from "react-router-dom";
import Grid from '@material-ui/core/Grid';

import AddIcon from '@material-ui/icons/Add';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Radio from "@material-ui/core/Radio/Radio";
import Button from '@material-ui/core/Button';
import { Modal } from 'antd';
import Slider from './MainWidget/Slider';
import Link from '@material-ui/core/Link';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import wzscImg from '../assets/images/wzsc.png';
import zshyImg from '../assets/images/zshy.png';
import jgsbImg from '../assets/images/jgsb.png';
import avatar from "../assets/images/tj.png";

import Toast from "antd-mobile/lib/toast";
import Api from '../redux/api/api';
import axios from "axios";

import { Input,Select } from 'antd';

const { Search } = Input;
const { Option } = Select;


const useStyles = makeStyles({
    root: {

        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}


const theme = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiTypography: {
            body1: {
                fontSize: '4.2vw',
                paddingRight: '5vw',
                lineHeight: '7vw'
            },
        },
    },
});

//医生端主页面
class IndexView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            bottomValue: 0,
            rows: [],
            showHome: null,
            curNav: 0,
            curRole: 0,
            changeRoleVisible: false,
            changeDepartmentVisible: false,
            schoolList: [],
            departmentList: [],
            gradeList: [],
            classList: [],
            schoolName: '',
            departmentName: '',
            gradeName: '',
            className: '',
            schoolId: localStorage.getItem("schoolId"),
            departmentId: '',
            gradeId: '',
            classId: '',
            schoolRegionList: [],
            schoolRegionId: localStorage.getItem("schoolRegionId"),
            schoolRegionName: localStorage.getItem("schoolRegionName"),
            doubleClickTs: 0,
        };
        this.navTo = this.navTo.bind(this);
        this.changeRole = this.changeRole.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEventCancel = this.handleEventCancel.bind(this);
        this.handleChangeRoleOk = this.handleChangeRoleOk.bind(this);
        this.editStudentInfo = this.editStudentInfo.bind(this);
        this.getRole = this.getRole.bind(this);
        this.getPatientListByRole = this.getPatientListByRole.bind(this);
        this.getStatusStr = this.getStatusStr.bind(this);

        this.onSearchName = this.onSearchName.bind(this);
        this.goScreenWork = this.goScreenWork.bind(this);

        this.onSchoolChange = this.onSchoolChange.bind(this);
        this.onSchoolSearch = this.onSchoolSearch.bind(this);
        this.onDepartmentChange = this.onDepartmentChange.bind(this);
        this.onGradeChange = this.onGradeChange.bind(this);
        this.onClassChange = this.onClassChange.bind(this);

        this.handleChangeDepartmentOk = this.handleChangeDepartmentOk.bind(this);
        this.handleEventCancelDepartment = this.handleEventCancelDepartment.bind(this);
        this.getSchoolList = this.getSchoolList.bind(this);
        this.getSchoolRegionList = this.getSchoolRegionList.bind(this);
        this.changeDepartment = this.changeDepartment.bind(this);

        this.goAddStudent = this.goAddStudent.bind(this);
    }

    componentDidMount() {
        const role = localStorage.getItem("curRole") == null ? 0 : localStorage.getItem("curRole");
        this.setState({
            curNav: localStorage.getItem("curRole") == null ? 0 : localStorage.getItem("curRole"),
            curRole: localStorage.getItem("curRole") == null ? 0 : localStorage.getItem("curRole"),
        })
        
        //加载学生列表
        this.getPatientListByRole(role);

        //处理学校院系年级班级
        let schoolRegionId = localStorage.getItem("schoolRegionId");
        let schoolRegionName = localStorage.getItem("schoolRegionName");
        if (schoolRegionId == null || schoolRegionName == null) {
            this.getSchoolList();
            setTimeout(() => { 
                this.setState({
                    changeDepartmentVisible: true,
                });
             }, 1000); 
        }
    }

    componentWillUnmount() {
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.serverInfo !== this.props.serverInfo) {
        }
    }

    getSchoolList() {
        const url = Api.getRootUrl() + '/api/region/getSchoolList'
        let that = this;
        let params = {
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
        }
        axios.post(url, params).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                that.setState({
                    schoolList: rsp.data.data?.schoolList
                })

            } else {
                Toast.info("获取数据失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("网络问题，请稍后再试！")
        })
        
    }

    getSchoolRegionList(schoolId) {
        const url = Api.getRootUrl() + '/api/region/getSchoolRegionList'
        let that = this;
        let params = {
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
            schoolId: schoolId
        }
        axios.post(url, params).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                that.setState({
                    schoolRegionList: rsp.data.data
                })
                //获取区域，直接更新院系选项
                let departmentList = [];
                let schoolRegionList = rsp.data.data;
                if(schoolRegionList != null && schoolRegionList.length > 0){
                    
                    for(let schoolRegion of schoolRegionList){
                        if(schoolRegion.type == 7){
                            departmentList.push(schoolRegion)
                            console.log(schoolRegion)
                        }
                    }
                }
                that.setState({
                    departmentList: departmentList,
                    gradeList: [],
                    classList: []
                })

            } else {
                Toast.info("获取数据失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("网络问题，请稍后再试！")
        })
    }

    getPatientListByRole(role) {
        const url = Api.getRootUrl() + '/api/doctor/getPatientList'
        let that = this;
        let params = {
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
            state: role,
            schoolRegionId: this.state.schoolRegionId,
            schoolId: this.state.schoolId,
            name: this.state.name
        }
        axios.post(url, params).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                that.setState({
                    patients: rsp.data.data?.patients
                })

            } else {
                Toast.info("获取数据失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("网络问题，请稍后再试！")
        })
    }

    getRole() {
        if (this.state.curRole == 1) return "注射化验";
        if (this.state.curRole == 2) return "结果上报";
        return "问诊筛查"
    }

    navTo(index) {
        this.setState({
            curNav: index,
        })
    }

    changeRole() {
        this.setState({
            changeRoleVisible: true,
            curNavTmp: this.state.curNav,
        })
    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });

    }

    handleEventCancel() {
        this.setState({
            changeRoleVisible: false,
        })
    }

    
    handleChangeRoleOk() {
        if (this.state.curNavTmp == null) return;
        this.setState({
            changeRoleVisible: false,
            curNav: this.state.curNavTmp,
            curRole: this.state.curNavTmp,
        })
        localStorage.setItem("curRole", this.state.curNavTmp)
        this.getPatientListByRole(this.state.curNavTmp)
    }

    changeDepartment(){
        this.setState({
            changeDepartmentVisible: true,
        })
        this.getSchoolList();
    }

    handleEventCancelDepartment() {
        this.setState({
            changeDepartmentVisible: false,
        });
    }
    handleChangeDepartmentOk(){
        this.setState({
            changeDepartmentVisible: false
        })
    }


    editStudentInfo(patient) {

        const now = new Date().getTime();
        const diff = now - this.state.doubleClickTs;
        if (diff > 300) { // 300毫秒内再次点击则认为是双击
            this.setState({
                doubleClickTs: now
            });
            return;
        }

        let patientId = patient.id
        const path = '/patientDetail';
        const params = {
            "patientId": patientId
        }
        this.props.history.push(path, params);
        /*
        if(patient.status == null){
            const path = `/doctorCheck`;
            const params = {
                patientId: patientId
            }
            this.props.history.push(path,params)
        }else{
            const path = '/patientDetail';
            const params = {
                "patientId": patientId
            }
            this.props.history.push(path, params);
        }
            */
    }

    goScreenWork(patient) {
        let patientId = patient.id
        const path = `/doctorCheck`;
        const params = {
            patientId: patientId
        }
        this.props.history.push(path,params)
    }

    getStatusStr(code){
        if(code == null) return "待填问卷";
        if(code < 0) return "已中止";
        if(code == 0) return "待问诊";
        if(code == 1) return "待检查";
        if(code == 2) return "待填报";
        if(code == 3) return "已填报";
    }

    onSearchName(value){
        this.state.name = value
        this.getPatientListByRole(this.state.curRole)
    }

    onSchoolChange(value){
        console.log(value)
        let schoolName = ''
        for(let school of this.state.schoolList){
            if(school.id == value) schoolName = school.name;
        }
        this.setState({
            schoolId: value,
            schoolName: schoolName
        })
        localStorage.setItem("schoolId",value);
        localStorage.setItem("schoolName",schoolName);
        this.getSchoolRegionList(value);
    }
    onSchoolSearch(value){
        console.log(value)
    }
    onDepartmentChange(value){
        console.log(value)
        let departmentName = ''
        for(let item of this.state.departmentList){
            if(item.id == value) departmentName = item.name;
        }
        this.setState({
            departmentId: value,
            departmentName: departmentName
        })
        
        //this.getSchoolRegionList(value);
        //检索年级信息
        let gradeList = []
        for(let grade of this.state.schoolRegionList){
            if(grade.parentId == value){
                gradeList.push(grade)
            }
        }
        this.setState({
            gradeList: gradeList,
            classList: [],
            schoolRegionId: value,
            schoolRegionName: this.state.schoolName+departmentName
        })
        localStorage.setItem("schoolRegionId",value)
        localStorage.setItem("schoolRegionName",this.state.schoolName+departmentName)
    }
    onGradeChange(value){
        console.log(value)
        let gradeName = ''
        for(let item of this.state.gradeList){
            if(item.id == value) gradeName = item.name;
        }
        this.setState({
            gradeId: value,
            gradeName: gradeName
        })
        
        //this.getSchoolRegionList(value);
        //检索班级信息
        let classList = []
        for(let classObj of this.state.schoolRegionList){
            if(classObj.parentId == value){
                classList.push(classObj)
            }
        }
        this.setState({
            classList: classList,
            schoolRegionId: value,
            schoolRegionName: this.state.schoolName+this.state.departmentName+gradeName
        })
        localStorage.setItem("schoolRegionId",value)
        localStorage.setItem("schoolRegionName",this.state.schoolName+this.state.departmentName+gradeName)
    }
    onClassChange(value){
        console.log(value)
        let className = ''
        for(let item of this.state.classList){
            if(item.id == value) className = item.name;
        }
        this.setState({
            classId: value,
            className: className,
            schoolRegionId: value,
            schoolRegionName: this.state.schoolName+this.state.departmentName+this.state.gradeName+className
        })
        localStorage.setItem("schoolRegionId",value)
        localStorage.setItem("schoolRegionName",this.state.schoolName+this.state.departmentName+this.state.gradeName+className)
    }

    goAddStudent() {
        let params = {
           hospitalId: localStorage.getItem("hospitalId"),
           hospitalName: localStorage.getItem("hospitalName"),
        }
        if(localStorage.getItem("schoolId") != null){
            params["hospitalId"] = localStorage.getItem("schoolId")
            params["hospitalName"] = localStorage.getItem("schoolName")
        }
        this.props.history.push(`/addStudent`,params);
     }

    render() {
        //get react-material css styles
        const { classes } = this.props;

        let item = { "name": "访视单", "className": "2027级高三2班", "createTime": "2023-05-12 10:21:11", "process": 0 }

        return (
            <Switch>
                {/*<Route path={`${this.props.match.path}/chatWindow`} component={ChatWindow}/>*/}
                <Grid className={classes.root}>
                    {/* header */}
                    <Grid className={classes.headerDiv} container item xs={12} justifyContent="center" alignContent="center">
                        <Grid item xs={8}>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b className={classes.defaultKang}>当前角色：{this.getRole()}</b>  &nbsp;&nbsp;<span><Link onClick={() => { this.changeRole() }}>更改{'>'}</Link></span>
                        </Grid>
                        <Grid className={classes.notification} item xs={4} justifyContent="flex-end" alignItems="center">
                            <AddIcon  onClick={()=>this.goAddStudent()}/>
                        </Grid>
                    </Grid>

                    {/* 轮播图 
                    <Grid item xs={12} className={classes.sliderImages}>
                        <Grid container justify="space-between" alignItems='center' className={classes.picListDiv}>
                            <Slider />
                        </Grid>
                    </Grid>*/}

                    <Grid item xs={12} className={classes.listDiv}>
                        <Grid container justify="space-between" alignItems='center' className={classes.searchBox}>
                            <Search placeholder="输入姓名搜索" onSearch={value => this.onSearchName(value)} className={classes.search}/>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.listDiv} >
                        <Grid container justify="space-between" alignItems='center' className={classes.departmentBox} >
                            <span>筛查班级：<b>{this.state.schoolRegionName}</b></span>&nbsp;&nbsp;<span><Link onClick={() => { this.changeDepartment() }}>选择{'>'}</Link></span>
                        </Grid>
                    </Grid>

                    

                    <Grid item xs={12} >
                        <Grid container spacing={0} className={classes.paperBox} justifyContent='space-evenly'>
                            <Grid container item xs={3} alignContent="center" justifyContent="center" alignItems='center'
                                className={this.state.curNav == 0 ? classes.paperActive : classes.paper}>
                                <Grid item>
                                    <img src={wzscImg} className={classes.navImg} title='问诊筛查' onClick={() => { this.navTo(0) }} />
                                </Grid>
                                <Grid item>
                                    <b>问诊筛查</b>
                                </Grid>

                            </Grid>
                            <Grid container item xs={3} alignContent="center" justifyContent="center" alignItems='center'
                                className={this.state.curNav == 1 ? classes.paperActive : classes.paper}>
                                <Grid item>
                                    <img src={zshyImg} className={classes.navImg} title='注射化验' onClick={() => { this.navTo(1) }} />
                                </Grid>
                                <Grid item>
                                    <b>注射化验</b>
                                </Grid>

                            </Grid>
                            <Grid container item xs={3} alignContent="center" justifyContent="center" alignItems='center'
                                className={this.state.curNav == 2 ? classes.paperActive : classes.paper}>
                                <Grid item>
                                    <img src={jgsbImg} className={classes.navImg} title='结果申报' onClick={() => { this.navTo(2) }} />
                                </Grid>
                                <Grid item>
                                    <b>结果上报</b>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid className={classes.listDiv}>


                        <Grid item xs={12} container direction="row" alignContent="center" justify="flex-start" GridWrap='nowrap'
                            className={this.state.patients == null ? classes.paperList : classes.hideMe}>
                            <Grid item xs={12} container direction="column" justify="center" className={classes.avatarDiv}>
                                无记录数据！
                            </Grid>
                        </Grid>

                        {(this.state.patients || []).map((patient) => {

                            return (
                                <Grid item xs={12} key={patient.id} container direction="row" alignContent="center" justify="flex-start" GridWrap='nowrap'
                                    className={classes.paperList}>
                                    <Grid item xs={3} container direction="column" justify="center" className={classes.avatarDiv}>
                                        {/*<Grid> <img src={avatar} className={classes.avatar} onClick={() => { this.editStudentInfo(patient) }} /> </Grid>*/}
                                        <Grid> <img src={avatar} className={classes.avatar} onClick={() => { this.goScreenWork(patient) }} /> </Grid>
                                        <Grid className={classes.m1}></Grid>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <ThemeProvider theme={theme}>
                                            <Typography component="p" className={classes.title}>
                                                姓名:<Link onClick={() => { this.goScreenWork(patient) }}>{patient.name}</Link>
                                            </Typography>
                                            <Typography component="p" className={classes.title}>
                                                班级：{patient.className}

                                            </Typography>
                                            <Typography component="p" className={classes.title} onClick={() => { this.editStudentInfo(patient) }}>
                                                状态：{this.getStatusStr(patient.status)}

                                            </Typography>
                                            <Typography component="p" className={classes.titleTime}>
                                                时间：{patient.createTime}
                                            </Typography>

                                        </ThemeProvider>
                                    </Grid>
                                </Grid>
                            )
                        })}


                        {/*
                        <Grid item xs={12} key={item.id} container direction="row" alignContent="center" justify="flex-start" GridWrap='nowrap'
                            className={classes.paperList}>
                            <Grid item xs={3} container direction="column" justify="center" className={classes.avatarDiv}>
                                <Grid> <img src={avatar} className={classes.avatar} onClick={() => { }} /> </Grid>
                                <Grid className={classes.m1}></Grid>
                            </Grid>

                            <Grid item xs={8}>
                                <ThemeProvider theme={theme}>
                                    <Typography component="p" className={classes.title}>
                                        姓名:{item.name}
                                    </Typography>
                                    <Typography component="p" className={classes.title}>
                                        班级：{item.className}

                                    </Typography>
                                    <Typography component="p" className={classes.title}>
                                        状态：待问诊

                                    </Typography>
                                    <Typography component="p" className={classes.titleTime}>
                                        时间：{item.createTime}
                                    </Typography>

                                </ThemeProvider>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} key={item.id} container direction="row" alignContent="center" justify="flex-start" GridWrap='nowrap'
                            className={classes.paperList}>
                            <Grid item xs={3} container direction="column" justify="center" className={classes.avatarDiv}>
                                <Grid> <img src={avatar} className={classes.avatar} onClick={() => { }} /> </Grid>
                                <Grid className={classes.m1}></Grid>
                            </Grid>

                            <Grid item xs={8}>
                                <ThemeProvider theme={theme}>
                                    <Typography component="p" className={classes.title}>
                                        姓名:{item.name}
                                    </Typography>
                                    <Typography component="p" className={classes.title}>
                                        班级：{item.className}

                                    </Typography>
                                    <Typography component="p" className={classes.title}>
                                        时间：{item.createTime}
                                    </Typography>

                                </ThemeProvider>
                            </Grid>
                        </Grid>
                         */}
                        
                        



                        <Grid className={classes.footDiv}></Grid>
                    </Grid>


                    <Modal title="变更角色"
                        visible={this.state.changeRoleVisible}
                        afterClose={this.handleEventCancel}
                        onCancel={this.handleEventCancel}
                        destroyOnClose={true}
                        forceRender={true}
                        footer={[
                            <Button key="back" onClick={this.handleEventCancel}>取消</Button>,
                            <Button key="submit" onClick={this.handleChangeRoleOk}>确定</Button>,
                        ]}
                    >
                        <Grid className={classes.bindContentFlex}>
                            <Grid className={classes.bindOptionLeftStyle}>角色类型：</Grid>
                            <RadioGroup className={classes.bindOptionRightStyle} row aria-label="gender"
                                name="curNavTmp"
                                onChange={this.handleInputChange}>
                                <FormControlLabel value="0" control={<StyledRadio />}
                                    label="问诊筛查" checked={this.state.curNavTmp == 0} />
                                <FormControlLabel value="1" control={<StyledRadio />}
                                    label="注射化验" checked={this.state.curNavTmp == 1} />
                                <FormControlLabel value="2" control={<StyledRadio />}
                                    label="结果上报" checked={this.state.curNavTmp == 2} />
                            </RadioGroup>
                        </Grid>
                    </Modal>

                    <Modal title="选择班级"
                        visible={this.state.changeDepartmentVisible}
                        afterClose={this.handleEventCancelDepartment}
                        onCancel={this.handleEventCancelDepartment}
                        destroyOnClose={true}
                        forceRender={true}
                        footer={[
                            <Button key="back" onClick={this.handleEventCancelDepartment}>取消</Button>,
                            <Button key="submit" onClick={this.handleChangeDepartmentOk}>确定</Button>,
                        ]}
                    >
                        <Grid className={classes.bindContentFlex}>
                            <Grid className={classes.bindOptionLeftStyle}>学校名称：</Grid>
                            <Grid className={classes.bindOptionRightStyle}>
                                <Select
                                    showSearch
                                    style={{width:'100%'}}
                                    placeholder="请选择学校"
                                    optionFilterProp="children"
                                    onChange={this.onSchoolChange}
                                    onSearch={this.onSchoolSearch}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {this.state.schoolList.filter(option => option.id).map((option, index) => (
                                        <Option key={index} value={option.id}>
                                        {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid className={classes.bindContentFlex}>
                            <Grid className={classes.bindOptionLeftStyle}>院系名称：</Grid>
                            <Grid className={classes.bindOptionRightStyle}>
                                <Select
                                    style={{width:'100%'}}
                                    placeholder="请选择院系"
                                    optionFilterProp="children"
                                    onChange={this.onDepartmentChange}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {this.state.departmentList.filter(option => option.id).map((option, index) => (
                                        <Option key={index} value={option.id}>
                                        {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid className={classes.bindContentFlex}>
                            <Grid className={classes.bindOptionLeftStyle}>年级名称：</Grid>
                            <Grid className={classes.bindOptionRightStyle}>
                                <Select
                                    style={{width:'100%'}}
                                    placeholder="请选择年级"
                                    optionFilterProp="children"
                                    onChange={this.onGradeChange}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {this.state.gradeList.filter(option => option.id).map((option, index) => (
                                        <Option key={index} value={option.id}>
                                        {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid className={classes.bindContentFlex}>
                            <Grid className={classes.bindOptionLeftStyle}>班级名称：</Grid>
                            <Grid className={classes.bindOptionRightStyle}>
                                <Select
                                    style={{width:'100%'}}
                                    placeholder="请选择班级"
                                    optionFilterProp="children"
                                    onChange={this.onClassChange}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {this.state.classList.filter(option => option.id).map((option, index) => (
                                        <Option key={index} value={option.id}>
                                        {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    </Modal>



                </Grid>

            </Switch>
        );
    }

}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (userInfo) => {
            // dispatch(loginRequest(userInfo))
        },
    }
}


//css region
const styles = theme => ({
    root: {
        display: 'block',
        backgroundColor: '#f5f5f9',
    },
    bodyDiv: {
        width: '100vw',
        height: '92vh',
    },
    headerDiv: {
        display: 'flex',
        width: '100vw',
        height: '14vw',
        lineHeight: '14vw',
        textAlign: 'left',
        background: 'linear-gradient(to left,#00c9e4, 50%, #00dfd7)',
        color: '#ffffff',
    },
    notification: {
        display: 'flex',
        paddingRight: '15px',
    },
    defaultKang: {
        //color: '#000000'
    },
    sliderImages: {
        height: '28vh',
    },
    picListDiv: {
        height: '100%',
        flexShrink: '1',
    },
    paperBox: {
        display: 'flex',
        flexGrow: 1,
        marginTop: '15px',
    },
    paper: {
        display: 'flex',
        padding: theme.spacing(1),
        border: '2px',
        borderRadius: '5px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.05)'
    },
    paperActive: {
        display: 'flex',
        padding: theme.spacing(1),
        border: '2px',
        borderRadius: '5px',
        backgroundColor: 'rgba(120, 235, 252, 1)',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.05)',
    },
    navImg: {
        height: '15vw',
    },

    listDiv: {
        flexGrow: 1,
        padding: theme.spacing(0, 3),
        backgroundColor: '#f5f5f9',
        paddingTop: theme.spacing(2),
    },
    paperList: {
        marginBottom: theme.spacing(2),
        border: '2vh',
        padding: '2vw',
        borderRadius: '16px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        boxShadow: '1px 1px 1px 2px rgba(0, 0, 0, 0.05)'
    },
    avatarDiv: {
        marginLeft: '1vw',
        marginRight: '4vw',
    },
    avatar: {
        width: '16vw',
        height: '16vw',
        maxWidth: '16vw',
        maxHeight: '16vw',
        marginLeft: theme.spacing(1),
        borderRadius: "50%"
    },
    m1: {
        height: '1vh'
    },
    title: {
        fontSize: '3.8vw',
        color: '#333333',
        marginRight: theme.spacing(0),
        padding: '0',
    },
    title_red: {
        fontSize: '4.2vw',
        color: 'red',
        marginRight: theme.spacing(0),
        padding: '0',
    },
    title_orange: {
        fontSize: '4.2vw',
        color: 'orange',
        marginRight: theme.spacing(0),
        padding: '0',
    },
    titleTime: {
        fontSize: '2.8vw',
        color: '#777777',
        marginRight: theme.spacing(0),
        padding: '0',
    },
    titleDiv: {
        height: '15vw',
        display: 'flex',
        flex: 1
    },

    footDiv: {
        height: '8vh',
        width: '100%',
        backgroundColor: '#f5f5f9',
    },
    bindOptionLeftStyle: {
        width: '35% !important',
        paddingLeft: '5%'
    },
    bindOptionRightStyle: {
        width: '60%',
        fontSize: '14px',
    },
    bindContentFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '2% !important'
    },
    hideMe: {
        display: 'none',
    },
    search: {
        marginTop: '1vw',
        marginBottom: '1vw',
    },
    selectBox: {
        width: '100%',
    },
    departmentBox: {
        padding: '5px',
    },
});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IndexView));