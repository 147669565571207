import * as ActionTypes from '../../actions/patients/login-action'

// The initial application state
let initialState = {
    loginView:{},
    returnCode:500,
    loginInfo:{},
    loginResult:{},
    wxBaseInfo: {},
    patientMobileUpdateSucceedCode : 500,
    modifyPwdSucceed : 500,
    baseInfoResult: {},
   loginOut: {}
}


// Takes care of changing the application state
function loginReducer(state = initialState, action) {
   switch (action.type) {

      case ActionTypes.TUI_CHU_DENG_LU_SUCCEED://患者端退出登录
         return {...state,loginOut:action.result}

        case ActionTypes.PATIENT_LOGIN_SUCCEED://患者端我的
            return {...state,loginView:action.result}

       case ActionTypes.WX_BASE_LOGIN_SUCCEED://患者端微信授权成功
          if(action.result.data){
             localStorage.setItem("userId",action.result.data.id);
             localStorage.setItem("patientId",action.result.data.patientId);
             localStorage.setItem("openId",action.result.data.wxOpenId);
             localStorage.setItem("userPhone",action.result.data.username);
          }
            return {...state, baseInfoResult:action.result}

        case ActionTypes.PATIENT_LOGIN0_SUCCEED: //患者端登录
           if(action.result.data){
              localStorage.setItem("userId",action.result.data.id);
              localStorage.setItem("patientId",action.result.data.patientId);
              localStorage.setItem("hospitalId",action.result.data.hospitalId);
              localStorage.setItem("hospitalName",action.result.data.hospitalName?action.result.data.hospitalName:'');
              localStorage.setItem("userPhone",action.result.data.username);
              localStorage.setItem("token",action.result.data.token);
           }
           return {...state,loginResult: action.result,returnCode:action.result.code}
        case ActionTypes.POST_PHONENUMBERS_SUCCEED://患者端我的手机号码修改提交
            return {...state, patientMobileUpdateSucceedCode: action.result}
        case ActionTypes.PATIENT_MODIFY_PASSWORD_SUCCEED://患者端修改密码
            return {...state, modifyPwdSucceed: action.result}
        case ActionTypes.SEND_VALID_CODE_SUCCEED://发送短信验证码
            return {...state, validCodeResult: action.result}
        case ActionTypes.PATIENT_REGISTER_ARRIVED://发送短信验证码
            return {...state, regResult: action.result}
        default:
            return state
    }

}

export default loginReducer
